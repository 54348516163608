<!-- Your component template -->
<div class="container-fluid px-0 hero-image">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <app-service-back-button link="/services"></app-service-back-button>
          </div>
      </div>

      <!-- Add a div for white space -->
      <div class="row">
          <div class="col-12" style="height: 50px;"> <!-- Adjust the height as needed -->
              <!-- Empty div for white space -->
          </div>
      </div>

      <div class="row row-cols-1 row-cols-md-1 row-cols-xl-2 g-5">
          <app-services-card linkText="Get a FREE Quote" header="Commercial Property" link="/quote" header_min="25px" description="Commercial Property insurance protects physical assets from unexpected events such as fire, hail, and wind damage. Commercial property covers: Buildings Business Personal Property Loss of Income"></app-services-card> 
      </div>
  </div>
</div>
