<app-about-hero></app-about-hero>


<div class="container pt-5" id="scrollTarget">
  <div class="row row-cols-1 row-cols-md-3 row-cols-xl-3 g-3">
    <!-- First Column -->
    <div class="col pb-5">
      <h2 class="pb-5">
        What We Can Do For You
      </h2>
      <div class="fs-5">
        <p>
          We offer a <strong> obligation review </strong>of your existing insurance policy to identify any gaps in coverage.
        </p>
          <p>
            We make sure you are<strong> properly covered </strong>and educated on other potential risk exposures that your business can encounter. 
          </p>
          <p>
            We practice risk management and offer<strong> business solutions </strong>to business owners.
        </p>
      </div>
    </div>

    <!-- Second Column -->
    <div class="feature col d-flex flex-column align-items-center justify-content-center">
      <div class="circle">
        <img src="../../../assets/images/Grid_3.jpg" alt="Workers Compensation Image"
          class="larger-image icon img-fluid">
      </div>
    </div>

    <!-- Third Column -->
    <div class="col">
      <h2 class="pb-5">
        Why Do Business with Us​
      </h2>
      <div class="fs-5">
        <p>
          We make the process of obtaining the proper insurance coverage simple and easy, 
          offering the proper guidance in terms of<strong> obtaining adequate coverage </strong>for your business.
        </p>
          <p>
          We educate by showing what can happen if the proper coverage is not in place. 
          </p>
          <p>
          With <strong>3 decades of experience</strong>, we are fully knowledgeable in our markets.
        </p>
      </div>
      
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col text-center mt-4">
      <div class="banner text-light" style="background-color: #4e6380; padding: 20px;">
        <h3>
          We find the <span class="yellow-text">RIGHT</span> coverage at the <span class="yellow-text">BEST</span> price, putting your business <span class="yellow-text">FIRST</span>.
        </h3>
      </div>
    </div>
  </div>
</div>
<!-- Empty div for white space -->
<div style="height: 150px; background-color: white;"></div>





