<div class="contact">

    
    <app-contact-hero></app-contact-hero>
    <div class="blue-background text-center py-4 text-white"><h2 class="fs-1">Call us for a <span class="yellow-text">FREE</span> quote: <br class="d-block d-lg-none"> <a href="tel:1-877-737-5271" class="text-light">1-877-737-5271</a></h2></div>
    <div class="container pb-5">
        <div class="row">
            <div class="col-12 col-md-7 g-3">
                <app-contact-form></app-contact-form>
                
            </div>
            <div class="col-12 col-md-5 mb-5 mt-5 g-3">
                <app-business-hours></app-business-hours>
                <div class="p-3">
                    <h2 class="#4e6380">Nevada Headquarters:</h2>
                    <br>
                    <p>107 E. Warm Springs Rd.</p>
                    <p>Las Vegas, NV, 89119</p>
                    
                    Office: <a href="tel:(702) 357-9898" class="blue-text">(702) 357-9898</a><br><br>
                    Email: <a class="blue-text" href="mailto:jpatrick@perlasinsurance.com">jpatrick@perlasinsurance.com</a>
                </div>
            </div>
        </div>
    </div>
    <div class="container pb-3">
        
        <h2 class="blue-text">Branch Offices: <i class="fas fa-map-marker-alt pin-icon"></i> </h2>
    </div>
    <app-contact-map [offices]="offices" class="d-none d-lg-block"></app-contact-map>
    <app-contact-dropdown [offices]="offices"></app-contact-dropdown>

</div>