<div class="hero-image">
    <div class="text-light">
        <div class="hero-text">
            <h1>SEE HOW EASY IT IS <br> TO GET COVERED</h1>
            <button class="btn btn-outline-light text-light fs-3" routerLink="/quote">Get A Quote</button>
        </div>
        <!-- Learn More text wrapped in a flexbox container -->
        <div class="learn-more-container" [style.opacity]="learnMoreOpacity">
            <div class="learn-more" id="learnMore" (click)="scrollToContent()">Learn More <br><i class="fas fa-chevron-down"></i></div>
        </div>
    </div>
</div>
