import { Component, OnInit, isDevMode } from '@angular/core';
import { FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

interface SelectBox {
  value: string;
  header: string;
  icon: string;
  description: string;
  custom: boolean;
}

@Component({
  selector: 'app-quote-services',
  templateUrl: './quote-services.component.html',
  styleUrls: ['./quote-services.component.scss']
})
export class QuoteServicesComponent implements OnInit {
  form!: FormGroup
  inDev = isDevMode()
  selectBoxes: SelectBox[] = [
    {
        value: "Workers-Comp",
        header: "Workers' Compensation",
        icon: "fas fa-briefcase-medical",
        description: "Injury and illness coverage for employees.",
        custom: false
    },
    {
      value: "General-Liability",
      header: "General Liability",
      icon: "fas fa-gavel",
      description: "Covers third-party liability claims for injuries to other people.",
      custom: false
    },
    {
        value: "Commercial-Property",
        header: "Commercial Property",
        icon: "fas fa-building",
        description: "Insurance for property damage/loss.",
        custom: false
    },
    {
        value: "Employment-Practices-Liability",
        header: "Employment Practices Liability",
        icon: "fas fa-balance-scale",
        description: "Covers employment liability claims.",
        custom: false
    },
    {
      value: "Professional-Liability",
      header: "Professional Liability",
      icon: "fas fa-shield-alt",
      description: "Protection against professional errors.",
      custom: false
    },
    {
        value: "Business Owner’s Policy",
        header: "Business Owner’s Policy",
        icon: "fas fa-store",
        description: "Package policy tailored for small businesses.",
        custom: false
    },
    {
      value: "Commercial Auto",
      header: "Commercial Auto",
      icon: "fas fa-truck",
      description: "Business vehicle coverage for liability and physical damage.",
      custom: false
    },
    {
      value: "Other",
      header: "Other",
      icon: "fas fa-ellipsis-h",
      description: "Various other coverage options to suit your business needs.",
      custom: true
  }
];

  constructor(private rootFormGroup: FormGroupDirective) {    
  }
  

  ngOnInit() : void {
    this.form = this.rootFormGroup.control
  }
}
