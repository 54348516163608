<div class="container px-4 py-5" id="featured-3" style="position: relative; overflow: hidden;">
    <div class="background-image" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: url('../../../assets/images/numbersimg.jpg') center center no-repeat; background-size: cover; opacity: 0.1;"></div>

    <h2 class="pb-2 off-left">Company Profile</h2>
    <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
        <div class="feature col d-flex flex-column align-items-center justify-content-center">
            <div class="circle">
                <img src="../../../assets/images/Grid_1.jpg" alt="Founded in 1990" class="icon img-fluid">
            </div>
        </div>

        <div class="feature col d-flex flex-column align-items-center justify-content-center">
            <div class="circle">
                <img src="../../../assets/images/Grid_3.jpg" alt="Proudly Celebrating 30+ Years In Business" class="larger-image icon img-fluid">
            </div>
        </div>

        <div class="feature col d-flex flex-column align-items-center justify-content-center">
            <div class="circle">
                <img src="../../../assets/images/Grid_5.jpg" alt="Over 3 Decades of Service" class="larger-image icon img-fluid">
            </div>
        </div>

        <div class="feature col d-flex flex-column align-items-center justify-content-center">
            <div class="circle">
                <img src="../../../assets/images/Grid_2.jpg" alt="Over 90% Policy Retention" class="larger-image icon img-fluid">
            </div>
        </div>

        <div class="feature col d-flex flex-column align-items-center justify-content-center">
            <div class="circle">
                <img src="../../../assets/images/Grid_4.jpg" alt="3000+ Healthcare Facilities Service throughout California and Nevada" class="larger-image icon img-fluid">
            </div>
        </div>

        <div class="feature col d-flex flex-column align-items-center justify-content-center">
            <div class="circle">
                <img src="../../../assets/images/Grid_6.jpg" alt="100+ Years of Combined Experience" class="larger-image icon img-fluid">
            </div>
        </div>
    </div>
</div>
