<div class="container">
  <div class="column">
    <div class="image-container">
      <img [src]="images[0].src" alt="{{ images[0].name }}" class="small-image">
      <img [src]="images[1].src" alt="{{ images[1].name }}" class="small-image">
    </div>
  </div>
  <div class="column">
    <div class="text-container">
      <h2>Our Offices</h2>
      <p>Nevada</p>
      <p>107 E. Warm Springs Rd.,
        Las Vegas, NV 89119</p>
      <!-- Add more text or content as needed -->
    </div>
  </div>
  <div class="column">
    <div class="image-container">
      <img [src]="images[2].src" alt="{{ images[2].name }}" class="small-image">
      <img [src]="images[3].src" alt="{{ images[3].name }}" class="small-image">
    </div>
  </div>
</div>
