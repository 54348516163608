import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SelectboxGroupComponent } from './selectbox-group.component';
import { SelectboxComponent } from '../selectbox/selectbox.component';


@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [SelectboxGroupComponent, SelectboxComponent],
  exports: [SelectboxGroupComponent, SelectboxComponent],
})
export class SelectboxGroupComponentModule {}