import { Component } from '@angular/core';

@Component({
  selector: 'app-servicespage',
  templateUrl: './servicespage.component.html',
  styleUrls: ['./servicespage.component.scss']
})
export class ServicespageComponent {

}
