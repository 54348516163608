<!-- Your component template -->
<div class="container-fluid px-0 hero-image">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <app-service-back-button link="/services"></app-service-back-button>
          </div>
      </div>

      <!-- Add a div for white space -->
      <div class="row">
          <div class="col-12" style="height: 20px;"> <!-- Adjust the height as needed -->
              <!-- Empty div for white space -->
          </div>
      </div>

      <div class="row row-cols-1 row-cols-md-1 row-cols-xl-2 g-3">
          <app-services-card linkText="Get a FREE Quote" header="Employment Practices Liability" link="/quote" header_min="25px" description="Employment Practices Liability insurance covers defense costs and damages for allegations such as discrimination, wrongful termination, workplace harassment, and wage and hour."></app-services-card> 
      </div>
  </div>
</div>
