import { Component } from '@angular/core';

@Component({
  selector: 'app-employerspractices',
  templateUrl: './employerspractices.component.html',
  styleUrls: ['./employerspractices.component.scss']
})
export class EmployerspracticesComponent {

}
