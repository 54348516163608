import { Component } from '@angular/core';

@Component({
  selector: 'app-generalprof',
  templateUrl: './generalprof.component.html',
  styleUrls: ['./generalprof.component.scss']
})
export class GeneralprofComponent {

}
