import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnChanges {
  @Input() currentPage: number = 1;
  @Input() totalNumberOfPages: number = 1;
  progressBarWidth: string = '0%';

  ngOnChanges(changes: SimpleChanges): void {
    if ('currentPage' in changes || 'totalNumberOfPages' in changes) {
      this.calculateProgressBarWidth();
    }
  }

  private calculateProgressBarWidth(): void {
    const progressPercentage = (this.currentPage / this.totalNumberOfPages) * 100;
    this.progressBarWidth = `${progressPercentage}%`;
  }
}
