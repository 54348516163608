import { Component, EventEmitter, Output, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

@Component({
  selector: 'app-selectbox-group',
  templateUrl: './selectbox-group.component.html',
  styleUrls: ['./selectbox-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectboxGroupComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectboxGroupComponent),
      multi: true,
    }
  ],
})
export class SelectboxGroupComponent implements ControlValueAccessor, Validator {
  value: string[] = [];
  @Output() valueChange = new EventEmitter<string[]>();

  constructor() {}

  onChange = (value: string[]) => {};
  onTouch = () => {};

  // Allow Angular to set the value on the component
  writeValue(value: string[]): void {
    if (value === null) return
    this.value = value;
  }

  // Save a reference to the change function passed to us by
  // the Angular form control
  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  // Save a reference to the touched function passed to us by
  // the Angular form control
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  toggleValue(selectedValue: string) {
    const index = this.value.indexOf(selectedValue);
    if (index > -1) {
      this.value = [
        ...this.value.slice(0, index),
        ...this.value.slice(index + 1),
      ];
    } else {
      this.value = [...this.value, selectedValue];
    }
    this.onChange(this.value);
    this.onTouch();

    this.valueChange.emit(this.value)
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.value && this.value.length > 0 ? null : { 'noSelection': true };
  }

  isSelected(valueToCheck: string) {
    return this.value.includes(valueToCheck);
  }
}