import { Component } from '@angular/core';

@Component({
  selector: 'app-contact-hero',
  templateUrl: './contact-hero.component.html',
  styleUrls: ['./contact-hero.component.scss']
})
export class ContactHeroComponent {
  

  
  
}
