<ng-container [formGroup]="form">


    <div class="container text-center" formGroupName="contactInfo">

        <h2>Name</h2>
        <div class="mb-3">
            <input type="text" formControlName="name" class="form-control centered-input mx-auto" placeholder="John Doe">
            <div *ngIf="form.get('contactInfo')?.get('name')?.invalid && (form.get('contactInfo')?.get('name')?.dirty || form.get('contactInfo')?.get('name')?.touched)"
                class="text-danger">
                <small *ngIf="form.get('contactInfo')?.get('name')?.errors?.['required']">Name is required.</small>
            </div>
        </div>

        <h2>Phone Number</h2>
        <div class="mb-3">
            <input type="text" formControlName="phoneNumber" class="form-control centered-input mx-auto" placeholder="(555) 555-1234">
            <div *ngIf="form.get('contactInfo')?.get('phoneNumber')?.invalid && (form.get('contactInfo')?.get('phoneNumber')?.dirty || form.get('contactInfo')?.get('phoneNumber')?.touched)"
                class="text-danger">
                <small *ngIf="form.get('contactInfo')?.get('phoneNumber')?.errors?.['required']">Phone Number is
                    required.</small>
                <small *ngIf="form.get('contactInfo')?.get('phoneNumber')?.errors?.['pattern']">Invalid phone number
                    format.</small>
            </div>
        </div>

        <h2>Email Address</h2>
        <div class="mb-3">
            <input type="text" formControlName="emailAddress" class="form-control centered-input mx-auto" placeholder="johnsmith@example.com">
            <div *ngIf="form.get('contactInfo')?.get('emailAddress')?.invalid && (form.get('contactInfo')?.get('emailAddress')?.dirty || form.get('contactInfo')?.get('emailAddress')?.touched)"
                class="text-danger">
                <small *ngIf="form.get('contactInfo')?.get('emailAddress')?.errors?.['required']">Email Address is
                    required.</small>
                <small *ngIf="form.get('contactInfo')?.get('emailAddress')?.errors?.['email']">Invalid email
                    address.</small>
            </div>
        </div>

        <div *ngIf="inDev">
            <h2>Form Page Values</h2>
            <div>{{form}}</div>
            <div *ngIf="form.get('contactInfo')?.valueChanges| async as values">
                <div>Values: {{ values | json }}</div>
            </div>
            <div> Valid: {{form.get('contactInfo')?.valid}}</div>
        </div>
    </div>
</ng-container>