<ng-container [formGroup]="form">


    <div class="container text-center" formGroupName="zip">

        <h2>Business ZIP code</h2>
        <div class="mb-3">
            <input type="text" formControlName="zipCode" class="form-control centered-input mx-auto" placeholder="Enter ZIP code">
            <div *ngIf="form.get('zip')?.get('zipCode')?.invalid && (form.get('zip')?.get('zipCode')?.dirty || form.get('zip')?.get('zipCode')?.touched)"
                class="text-danger">
                <small *ngIf="form.get('zip')?.get('zipCode')?.errors?.['required']">ZIP code is required.</small>
                <small *ngIf="form.get('zip')?.get('zipCode')?.errors?.['pattern']">Invalid ZIP code format.</small>
            </div>
        </div>

        <div *ngIf="inDev">
            <h2>Form Page Values</h2>
            <div>{{form}}</div>
            <div *ngIf="form.get('zip')?.valueChanges| async as values">
                <div>Values: {{ values | json }}</div>
            </div>
            <div> Valid: {{form.get('zip')?.valid}}</div>
        </div>

    </div>
</ng-container>