<ng-container [formGroup]="form">


    <div class="container" formGroupName="services">
        <div class="text-center">
            <h2 class="mb-3">Select One Or More Service(s)</h2>
            <p class="lead">Not sure what you need? Click <a routerLink="/services" class="fw-bold">HERE</a></p>
        </div>
        <app-selectbox-group formControlName="selectboxes">
            <div *ngFor="let box of selectBoxes; let i = index">
                <app-selectbox [id]="i + 1" [value]="box.value" [header]="box.header" 
                               [icon]="box.icon" [description]="box.description"
                               [custom]="box.custom">
                    <input type="text" formControlName="otherService" class="form-control mt-4" placeholder="Enter text">
                </app-selectbox>
            </div>            
        </app-selectbox-group>
        <div *ngIf="inDev">
            <h2>Form Page Values</h2>
            <div>{{form}}</div>
            <div *ngIf="form.get('services')?.valueChanges| async as values">
                <div>Values: {{ values | json }}</div>
            </div>
            <div> Valid: {{form.get('services')?.valid}}</div>
        </div>
    </div>
</ng-container>