<!-- navbar.component.html -->
<nav class="navbar navbar-expand-lg fixed-top p-3 p-lg-1" [ngClass]="{'hide-navbar': !navbarVisible, 'navbar-dark': navbarOpen}">
  <div class="container-fluid ">
    <!-- Brand -->
    <div class="d-flex w-100 justify-content-lg-start">
      <a class="mx-auto navbar-brand" routerLink="/" (click)="closeNavbar()">
        <img class="logo img-fluid" src="../../../assets/images/brand/PI_logo-white.png" alt="Perlas Insurance Logo">
      </a>
    </div>    
    <!-- Hamburger Icon for Mobile -->
    <button class="navbar-toggler navbar-dark p-1" type="button" (click)="toggleNavbar()" data-bs-toggle="collapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon rounded"></span>
    </button>
    <!-- Navbar Links -->
    <div class="collapse container navbar-collapse row-eq-height" [ngClass]="{'show': navbarOpen}">
      <div class="navbar-nav row g-4 justify-content-around">
        <li class="col text-center align-items-center text-light" *ngFor="let page of pages; let i = index">
          <a class="nav-link fade-in rounded fs-3" (click)="closeNavbar()" [ngClass]="{'nav-link-animate': navbarOpen}"  [style.animation-delay]="navbarOpen ? (i * 100) + 'ms' : '0ms'" routerLink="{{page.link}}">{{page.name}}</a>
        </li>
      </div>
    </div>
  </div>
</nav>

