import { Component } from '@angular/core';

@Component({
  selector: 'app-differentiators',
  templateUrl: './differentiators.component.html',
  styleUrls: ['./differentiators.component.scss']
})
export class DifferentiatorsComponent {

}
