import { Routes } from '@angular/router';
import { HomeComponent } from './home/home/home.component';
import { ContactComponent } from './contact/contact/contact.component';
import { PrivacyPolicyComponent } from './misc/privacy-policy/privacy-policy.component';
import { FormsComponent } from './forms/forms.component';
import { QuoteComponent } from './quote/quote.component';
import { TermsConditionsComponent } from './misc/terms-conditions/terms-conditions.component';
import { ConfirmDeactivateGuard } from './_guards/can-component-deactivate.guard';

export const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then(m => m.AboutModule)
  },
  {
    path: 'services',
    loadChildren: () => import('./servicespage/services.module').then(m => m.ServicesModule)
  },
  { path: 'contact', component: ContactComponent },
  { path: 'privacy', component: PrivacyPolicyComponent },
  { path: 'quote', component: QuoteComponent, canDeactivate: [ConfirmDeactivateGuard] },
  { path: 'forms', component: FormsComponent },
  { path: 'termsconditions', component: TermsConditionsComponent},
  { path: '**', redirectTo: '', pathMatch: 'full' }
];
