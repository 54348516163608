import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { FormService } from 'src/app/_services/submit-form.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent {
  loading = false;
  successMessage = '';
  errorMessage = '';
  reCAPTCHASiteKey = environment.reCAPTCHASiteKey;
  contactForm = new FormGroup({
    emailAddress: new FormControl('', [Validators.required, Validators.email]),
    name: new FormControl('', [Validators.required]),
    message: new FormControl('', [Validators.required, Validators.maxLength(4000)]),
  });
  constructor(private formService: FormService, private recaptchaV3Service: ReCaptchaV3Service) {
  }

  submitForm() {
    if (this.contactForm.valid) {
      this.loading = true;
      this.successMessage = '';
      this.errorMessage = '';
      // Execute reCAPTCHA v3
      this.recaptchaV3Service.execute('submitForm').subscribe((token) => {
        // Append the token to your form data
        const formDataWithToken = {
          ...this.contactForm.value,
          captchaToken: token,
          formType: "contact"
        };

        // Now submit the form data with the token
        this.formService.submitFormData(formDataWithToken)
          .subscribe({
            next: (response) => {
              this.successMessage = 'Form submitted successfully';
              this.loading = false;
              this.contactForm.reset();
            },
            error: (error) => {
              this.errorMessage = `Error occurred when submitting form: ${error.status} ${error.message}`;
              this.loading = false;
            }
          });
      });
    }
  }
}
