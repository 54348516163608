import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';  // Use the generic environment

@Injectable({
  providedIn: 'root'
})
export class FormService {
  constructor(private http: HttpClient) {}

  submitFormData(formData: any) {
    const formDataWithTime = {
      ...formData,
    };
    const lambdaUrl = environment.contactFormUrl;  // This will point to the correct URL based on the environment
    console.log(formDataWithTime);
    return this.http.post(lambdaUrl, formDataWithTime, environment.HTTP_OPTIONS);
  }
}
