import { Component, OnDestroy, OnInit, isDevMode } from '@angular/core';
import { QuoteService } from '../_services/quote.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { FormService } from '../_services/submit-form.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable } from 'rxjs';
import { CanComponentDeactivate } from '../_guards/can-component-deactivate.guard';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
})
export class QuoteComponent implements CanComponentDeactivate{
  inDev = isDevMode();
  submissionLoading: boolean = false;
  submissionSuccessMessage: string = '';
  submissionFailureMessage: string = '';
  rootForm!: FormGroup;
  currentPage = 0;
  totalPages: number; // Total number of pages

  constructor(
    private quoteService: QuoteService,
    private fb: FormBuilder,
    private formService: FormService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {

    if (isDevMode()) {
      this.rootForm = this.fb.group({
        services: this.fb.group({
          selectboxes: [['Workers-Comp']],
          otherService: {value: '', disabled: false}
        }),
        zip: this.fb.group({
          zipCode: [
            '41241',
            [Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')],
          ],
        }),
        businessInfo: this.fb.group({
          industryName: [null, [Validators.required]],
          businessName: [null, [Validators.required]],
          insurancePolicyExpiration: [null],  // Not required
        }),
        contactInfo: this.fb.group({
          name: [null, [Validators.required]],  // Name field is required
          phoneNumber: [
            null,
            [
              Validators.required,
              Validators.pattern(
                /^(\+?\d{1,3}?[- .]?)?(\(?\d{3}\)?[- .]?)?[\d- .]{7,15}$/
              ),
            ],
          ],
          emailAddress: ['test@gmail.com', [Validators.required, Validators.email]],
        }),
      });
    } else {
      this.rootForm = this.fb.group({
        services: this.fb.group({
          selectboxes: [[]],
          otherService: {value: '', disabled: false}
        }),
        zip: this.fb.group({
          zipCode: [
            null,
            [Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')],
          ],
        }),
        businessInfo: this.fb.group({
          industryName: [null, [Validators.required]],
          businessName: [null, [Validators.required]],
          insurancePolicyExpiration: [null],  // Not required
        }),
        contactInfo: this.fb.group({
          name: [null, [Validators.required]],  // Name field is required
          phoneNumber: [
            null,
            [
              Validators.required,
              Validators.pattern(
                /^(\+?\d{1,3}?[- .]?)?(\(?\d{3}\)?[- .]?)?[\d- .]{7,15}$/
              ),
            ],
          ],
          emailAddress: [null, [Validators.required, Validators.email]],
        }),
      });
    }
    
    this.totalPages = Object.keys(this.rootForm.controls).length;
    
  }

  scrollToTop(): void {
    const topElement = document.getElementById('top');
    if (topElement) {
      topElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  

  isCurrentPageValid(): boolean {
    switch (this.currentPage) {
      case 0:
        return this.rootForm.get('services')?.valid ?? false;
      case 1:
        return this.rootForm.get('zip')?.valid ?? false;
      case 2:
        return this.rootForm.get('businessInfo')?.valid ?? false;
      case 3:
        return this.rootForm.get('contactInfo')?.valid ?? false;
      default:
        return false;
    }
  }

  goToPreviousPage(): void {
    if (this.currentPage > 0) {
      this.currentPage--;
      this.scrollToTop();
    }
  }

  updateOther(): void {
    // Retrieve the current value of selectboxes
    const selectBoxesValue = this.rootForm.get('services.selectboxes')!.value;
  
    // Check if "Other" is in the selectBoxes array
    if (!selectBoxesValue.includes('Other')) {
      // Set otherService value to an empty string if "Other" is present
      this.rootForm.get('services.otherService')!.setValue('');
    } else {
      // Optional: Set otherService to a default value or leave as is
      // this.rootForm.get('services.otherService').setValue('default value');
    }
  }

  goToNextPage(): void {
    if (this.currentPage < this.totalPages - 1 && this.isCurrentPageValid()) {
      this.currentPage++;
      this.updateOther()
      this.scrollToTop();
    }
  }

  private flattenObject(obj: any) {
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];

      // Check if the property is an object and not null
      if (typeof value === 'object' && value !== null) {
        return { ...acc, ...value };
      }

      return acc;
    }, {});
  }
  private joinStringArrays(obj: { [key: string]: any }): {
    [key: string]: any;
  } {
    const result: { [key: string]: any } = {};

    Object.keys(obj).forEach((key) => {
      const value = obj[key];

      // If the value is an array of strings, join it with ", "
      if (
        Array.isArray(value) &&
        value.every((element) => typeof element === 'string')
      ) {
        result[key] = value.join(', ');
      } else {
        result[key] = value;
      }
    });

    return result;
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    // Check if the form is dirty
    return !this.rootForm.dirty || confirm('You have unsaved changes! Are you sure you want to leave?');
  }

  onSubmit(): void {
    if (this.rootForm.valid) {
      // Set UI for loading/success
      this.submissionLoading = true;
      this.submissionSuccessMessage = '';
      this.submissionFailureMessage = '';
      // Flatten form data
      console.log(this.rootForm.value);
      let formDataWithoutToken = {
        ...this.rootForm.value,
      };
      // Flatten the formgroups into one object
      formDataWithoutToken = this.flattenObject(formDataWithoutToken);
      // Join arrays of strings to prevent server side validations from failing
      formDataWithoutToken = this.joinStringArrays(formDataWithoutToken)
      // Execute reCAPTCHA v3
      this.recaptchaV3Service.execute('submitForm').subscribe((token) => {
        // Append the token to your form data
        const formDataWithToken = {
          ...formDataWithoutToken,
          captchaToken: token,
          formType: 'quote',
        };
        console.log(formDataWithToken)

        this.formService.submitFormData(formDataWithToken).subscribe({
          next: (response) => {
            console.log(response)
            this.submissionSuccessMessage = 'Form submitted successfully';
            this.submissionLoading = false;
            this.currentPage ++;
            this.rootForm.reset();
          },
          error: (error) => {
            console.log(error)
            this.submissionFailureMessage = `Error occurred when submitting form: ${error.status} ${error.message}`;
            this.submissionLoading = false;
          },
        });
      });
    }
  }
}
