<div class="image col-12 text-center bg-light p-3 p-lg-5 mx-auto text-white align-items-center">
    <div class="">
        <h2 class="text-center">Our Differentiators</h2>
    
        <div class="row px-lg-5 justify-content-center">
            <div class="col-md-4">
                <h3 style="font-size: 150px;" class="pt-3">1</h3>
                <p class="fs-4"><b>We value</b><br> our clients, putting you and your business first</p>
            </div>
            <div class="col-md-4">
                <h3 style="font-size: 150px;" class="pt-3">2</h3>
                <p class="fs-4"><b>We deliver</b><br> comprehensive insurance solutions to the table</p>
            </div>
            <div class="col-md-4">
                <h3 style="font-size: 150px;" class="pt-3">3</h3>
                <p class="fs-4"><b>We strategize</b><br> a business plan to minimize preventable risk</p>
            </div>
        </div>
    </div>
    </div>