<!-- Your component template -->
<div class="container-fluid px-0 hero-image">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <app-service-back-button link="/services"></app-service-back-button>
            </div>
        </div>

        <!-- Add a div for white space -->
        <div class="row">
            <div class="col-12" style="height: 50px;"> <!-- Adjust the height as needed -->
                <!-- Empty div for white space -->
            </div>
        </div>

        <div class="row row-cols-1 row-cols-md-1 row-cols-xl-3 g-3">
            <app-services-card linkText="Get a FREE Quote" header="Workers' Compensation" link="/quote" header_min="25px" description="Workers' Compensation insurance covers employees who are injured or become disabled as part of their job. At Perlas, we ensure your business meets local state regulations."></app-services-card>
        </div>
    </div>
</div>
