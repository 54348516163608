import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-dropdown',
  templateUrl: './contact-dropdown.component.html',
  styleUrls: ['./contact-dropdown.component.scss']
})
export class ContactDropdownComponent implements OnInit {
  @Input() offices: any[] = [];
  groupedOffices: { [key: string]: any[] } = {};

  ngOnInit() {
    this.groupOfficesByState();
  }

  groupOfficesByState() {
    this.groupedOffices = this.offices.reduce((groups, office) => {
      const state = office.state;
      if (!groups[state]) {
        groups[state] = [];
      }
      groups[state].push(office);
      return groups;
    }, {});
  }

  getStates(): string[] {
    // Return the states sorted alphabetically
    return Object.keys(this.groupedOffices).sort();
  }

  toggle(state: string) {
    this.groupedOffices[state].forEach(office => office.open = !office.open);
  }
}
