import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ServicespageComponent } from './servicespage.component';
import { WorkerscompComponent } from './workerscomp/workerscomp.component';
import { CommercialpropertyComponent } from './commercialproperty/commercialproperty.component';
import { EmployerspracticesComponent } from './employerspractices/employerspractices.component';
import { GeneralprofComponent } from './generalprof/generalprof.component';
import { ServicesMainComponent } from './servicespage-hero/services-main/services-main.component';
import { ServicespageHeroComponent } from './servicespage-hero/servicespage-hero.component';
import { SharedModule } from '../shared/shared.module';

const routes: Routes = [
  { path: '', component: ServicespageComponent },
  { path: 'workerscomp', component: WorkerscompComponent },
  { path: 'commercialproperty', component: CommercialpropertyComponent },
  { path: 'epli', component: EmployerspracticesComponent },
  { path: 'generalprof', component: GeneralprofComponent }
];

@NgModule({
  declarations: [
    ServicespageComponent,
    ServicespageHeroComponent,
    WorkerscompComponent,
    CommercialpropertyComponent,
    EmployerspracticesComponent,
    GeneralprofComponent,
    ServicesMainComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule
  ]
})
export class ServicesModule { }
