import { Component } from '@angular/core';

@Component({
  selector: 'app-image-map',
  templateUrl: './image-map.component.html',
  styleUrls: ['./image-map.component.scss']
})
export class ImageMapComponent {
  images = [
    { name: 'Cali1', src: '../../../assets/images/cali1.png' },
    { name: 'Texas1', src: '../../../assets/images/texas1.png' },
    { name: 'Nevada1', src: '../../../assets/images/nevada1.png' },
    { name: 'Hawaii1', src: '../../../assets/images/hawaii1.png' }
  ];
}
