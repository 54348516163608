<div class="container">
    <div class="usa-map usa-map-content">
        <div *ngFor="let office of offices" > 
            <app-pin 
            [ngStyle]="{'left.%': office.x, 'top.%': office.y}"
            (click)="clearMap(office)">
                
            </app-pin>
            <app-hover-card *ngIf="office.visible"
                [class.show-card]="office.visible"
                [ngStyle]="{'left.%': office.x-3, 'top.%': office.y-21}">
                <button class="close-btn" (click)="clearMap(office)"><i class="fas fa-times text-white"></i></button>
                <h3 class="light-blue-text">{{office.displayName}}</h3>
                <address>
                    <p><a [href]="'https://www.google.com/maps/search/?api=1&query=' + office.completedAddress()" target="_blank" class="light-blue-text">
                    {{office.address1}} {{office.address2}} <br>
                    {{office.city}},
                    {{office.state}},
                    {{office.zip}}
                    </a></p>
                    <p class="light-blue-text">Office: <a class="light-blue-text" href="tel:{{office.phone}}" target="_blank">{{office.phone}}</a></p>
                </address>
            </app-hover-card>
        </div>
    </div>
    <div class="text-center pb-5">
        <h3 class="blue-text">Licensed in:</h3>
        <p>
Alaska  |  Arizona  |  California  |  Florida  |   Hawaii  |  Maryland  |  Nevada  |  New Jersey  |  Oregon  |  Texas  |  Virginia

        </p>
    </div>
</div>


  