
<div class="hero-image">

</div>

<div class="container-fluid p-0">
    <div class="row g-0">
        <div class="col col-12 col-lg-7">
            <div class="shaking-hands-div">
                <div class="patrick-summary text-light">
                    <h1>
                        <strong>Patrick Perlas</strong>
                    </h1>
                    <p class="py-3 fs-5">
                        <span class="yellow-text">Patrick Perlas</span>, CIC, LUTCF is an entrepreneur and business leader who began his career in sales. He is the President and CEO of the Perlas Group, Inc. and Perlas Insurance Services.​
                    </p>
                    <h2>
                        <strong>Distinguished Professional <br> Recognitions:</strong>
                    </h2>
                    <div class="row pt-4">
                        <div class="col col-12 col-lg-6">
                            <h3 class="certification-title">
                                <span class="yellow-text fs-4">CIC: Certified Insurance Counselor</span>
                            </h3>
                            <p>
                                Nationally recognized and highly respected insurance professional designation for agency owners, producers, agents, CSR’s, brokers, and insurance company personnel.​
                            </p>
                        </div>
                        <div class="col col-12 col-lg-6">
                            <h3 class="certification-title">
                                <span class="yellow-text fs-4">Life Underwriting Training Council Fellow​</span>
                            </h3>
                            <p>
                                Recognized as the industry benchmark for insurance credentials and is endorsed by the top financial firms and insurance agencies.​
                            </p>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </div>
        <div class="col col-12 col-lg-5">
            <div class="col col-12 col-lg-12">
                <div class="patrick-computer-div">

                </div>
            </div>
            <div class="col col-12 col-lg-12">
                <div class="interview-background  d-flex text-center align-items-center justify-content-center">
                    <a class="btn btn-custom fs-4" href="/assets/files/perlas-insurance-patrick-interview.pdf" target="_blank">An Interview with Patrick</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- 
<div class="container-fluid designations" id="scrollTarget">
    <div class="row">
      <div class="col text-center mt-0">
        <div class="banner mx-auto" style="background-color: #4e6380; padding: 20px;">
          <h3>
            <span style="font-weight: bold; font-size: 48px; color: white;">Distinguished Designations</span>
          </h3>
        </div>
      </div>
    </div>
  
    <div class="row mt-5">
      <div class="col d-flex flex-column align-items-center justify-content-center feature">
        <div>
          <img src="../../../assets/images/LUTCF-black-logo.png" alt="Workers Compensation Image" class="icon img-fluid">
        </div>
      </div>
    </div>
  </div> -->