import { Component } from '@angular/core';

@Component({
  selector: 'app-workerscomp',
  templateUrl: './workerscomp.component.html',
  styleUrls: ['./workerscomp.component.scss']
})
export class WorkerscompComponent {

}
