import { Component, Host, Input} from '@angular/core';
import { SelectboxGroupComponent } from '../selectbox-group/selectbox-group.component';

@Component({
  selector: 'app-selectbox',
  templateUrl: './selectbox.component.html',
  styleUrls: ['./selectbox.component.scss']
})
export class SelectboxComponent {
  @Input() id!: number;
  @Input() value: string = '';
  @Input() header: string = '';
  @Input() custom: boolean = false;
  @Input() icon: string = 'fas fa-icons'
  @Input() description: string = 'description'
  constructor(@Host() public checkboxGroup: SelectboxGroupComponent) {}
}

