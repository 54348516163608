import { Component, Input } from '@angular/core';

export class Office {
  displayName: string = '';
  address1: string = '';
  address2: string = '';
  city: string = '';
  state: string = '';
  long_state: string = '';  // Add this line
  zip: string = '';
  phone: string = '';
  x: number = 0;
  y: number = 0;
  visible: boolean = false;
  open: boolean = false;

  constructor(init?: Partial<Office>) {
    Object.assign(this, init);
  }

  completedAddress(): string {
    return this.address1 + ' ' + this.address2 + ' ' + this.city + ' ' + this.state + ' ' + this.zip;
  }
}

@Component({
  selector: 'app-contact-map',
  templateUrl: './contact-map.component.html',
  styleUrls: ['./contact-map.component.scss']
})
export class ContactMapComponent {
  @Input() offices: Office[] = [];

  clearMap(exceptOffice?: Office): void {
    this.offices.forEach(office => {
      if (!exceptOffice || office.displayName !== exceptOffice.displayName) {
        office.visible = false;
      }
    });
    if (exceptOffice) {
      exceptOffice.visible = !exceptOffice.visible;
    }
  }

  toggleCardVisibility(office: Office): void {
    office.visible = !office.visible;
  }
}
