
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  navbarOpen = false;
  pages = [
      { name: 'Get Quote', link: 'quote' },
      { name: 'Home', link: 'home' },
      { name: 'About', link: 'about' },
      { name: 'Services', link: 'services' },
      { name: 'Contact', link: 'contact' },
      // Add other pages here
  ];

  private lastScrollTop = 0;
  navbarVisible = true;

  constructor(private router: Router) {
    // Subscribe to router events
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.navbarVisible = true; // Make navbar visible on route change
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    let currentScroll = window.pageYOffset || document.documentElement.scrollTop;
    if (currentScroll > this.lastScrollTop) {
      this.navbarVisible = false; // Scrolling down, hide the navbar
    } else {
      this.navbarVisible = true; // Scrolling up, show the navbar
    }
    this.lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // For Mobile or negative scrolling
  }

  toggleNavbar() {
      this.navbarOpen = !this.navbarOpen;
  }

  closeNavbar() {
    this.navbarOpen = false;
  }
}
