import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-services-card',
  templateUrl: './services-card.component.html',
  styleUrls: ['./services-card.component.scss']
})
export class ServicesCardComponent {
  @Input() header: string = '';
  @Input() link: string ='';
  @Input() icon: string = '';
  @Input() description: string = '';
  @Input() linkText: string = 'Get Info';
  @Input() image: string ='';
  @Input() download: boolean = false;
  @Input() header_min: string = '100px';

  openLinkInNewTab(link: string): void {
    window.open(link, '_blank');
  }
  openLinkInCurrentTab(link: string): void {
    window.location.href = link;
  }
}
