import { Component } from '@angular/core';
import { Office } from '../contact-map/contact-map.component'; // Adjust the path as needed

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  offices: Office[] = [
    new Office({
      displayName: 'Union City',
      address1: '32150 Alvarado Blvd.',
      address2: '',
      city: 'Union City',
      state: 'CA',
      long_state: 'California',  // Add this line
      zip: '94587',
      phone: '(510) 945-0010',
      x: 7.5,
      y: 38.5
    }),

    new Office({
      displayName: 'Las Vegas (Main Office)',
      address1: '107 E. Warm Springs Rd.',
      address2: '',
      city: 'Las Vegas',
      state: 'NV',
      long_state: 'Nevada',  // Add this line
      zip: '89119',
      phone: '(702) 357-9898',
      x: 13,
      y: 46
    }),

    new Office({
      displayName: 'La Palma',
      address1: '12 Centerpointe Dr.',
      address2: 'Suite 204',
      city: 'La Palma',
      state: 'CA',
      long_state: 'California',  // Add this line
      zip: '90623',
      phone: '(562) 414-0248',
      x: 6.5,
      y: 52.5
    }),

    new Office({
      displayName: 'Honolulu',
      address1: '500 Ala Moana Blvd.',
      address2: 'Suite 7-400',
      city: 'Honolulu',
      state: 'HI',
      long_state: 'Hawaii',  // Add this line
      zip: '96813',
      phone: '(808) 809-4423',
      x: 27,
      y: 83
    }),

    new Office({
      displayName: 'Houston',
      address1: '2929 Allen Parkway',
      address2: 'Suite 200',
      city: 'Houston',
      state: 'TX',
      long_state: 'Texas',  // Add this line
      zip: '77019',
      phone: '(877) 737-5271',
      x: 50,
      y: 77
    }),

    new Office({
      displayName: 'Phoenix',
      address1: '11811 N. Tatum Blvd',
      address2: 'Suite 3031',
      city: 'Phoenix',
      state: 'AZ',
      long_state: 'Arizona',  // Add this line
      zip: '85028',
      phone: '(602) 466-9298',
      x: 18,
      y: 58
    }),
  ];
}
