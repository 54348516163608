<div class="container">
  <h3>For specific questions, get in touch below</h3>
  <div *ngFor="let state of getStates()" class="my-3">
    <button class="btn btn-link w-100 text-left d-flex justify-content-between align-items-center" (click)="toggle(state)">
        {{ groupedOffices[state][0].long_state }}  <!-- Use long_state here -->
        <i class="fas" [ngClass]="{'fa-chevron-down': !groupedOffices[state][0].open, 'fa-chevron-up': groupedOffices[state][0].open}"></i>
    </button>
    <div *ngIf="groupedOffices[state][0].open" class="collapse show">
        <div class="card card-body">
            <div class="row">
                <div *ngFor="let office of groupedOffices[state]" class="col-md-6 mb-3">
                    <h4>{{ office.displayName }}</h4>
                    <p>{{ office.address1 }} <br> 
                    {{ office.address2 }} <br *ngIf="office.address2">
                    {{ office.city }}, {{ office.state }}, {{ office.zip }}
                    </p>
                    <p>Office: <a [href]="'tel:' + office.phone">{{office.phone}}</a></p>
                </div>
            </div>
        </div>
    </div>
  </div>
</div>
