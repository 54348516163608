import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home/home.component';
import { NavbarComponent } from './nav/navbar/navbar.component';
import { HomeHeroComponent } from './home/home-hero/home-hero.component';
import { ContactComponent } from './contact/contact/contact.component';
import { FooterComponent } from './footer/footer/footer.component';
import { PrivacyPolicyComponent } from './misc/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './misc/terms-conditions/terms-conditions.component';
import { ContactHeroComponent } from './contact/contact-hero/contact-hero.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AboutComponent } from './about/about.component';
import { FormsComponent } from './forms/forms.component';
import { ServicespageComponent } from './servicespage/servicespage.component';
import { ServicespageHeroComponent } from './servicespage/servicespage-hero/servicespage-hero.component';
import { WorkerscompComponent } from './servicespage/workerscomp/workerscomp.component';
import { GeneralprofComponent } from './servicespage/generalprof/generalprof.component';
import { CommercialpropertyComponent } from './servicespage/commercialproperty/commercialproperty.component';
import { EmployerspracticesComponent } from './servicespage/employerspractices/employerspractices.component';
import { CEOIntroductionComponent } from './about/ceo-introduction/ceo-introduction.component';
import { AboutHeroComponent } from './about/about-hero/about-hero.component';
import { ImageMapComponent } from './image-map/image-map.component';
import { HttpClientModule } from '@angular/common/http';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { QuoteComponent } from './quote/quote.component';
import { ProgressBarComponent } from './quote/progress-bar/progress-bar.component';
import { SelectboxGroupComponentModule } from './shared/ui/selectbox-group/selectbox-group.module';
import { QuoteServicesComponent } from './quote/quote-services/quote-services.component';
import { QuoteZipComponent } from './quote/quote-zip/quote-zip.component';
import { QuoteBusinessInfoComponent } from './quote/quote-business-info/quote-business-info.component';
import { QuoteContactInfoComponent } from './quote/quote-contact-info/quote-contact-info.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContactMapComponent } from './contact/contact-map/contact-map.component';
import { ContactFormComponent } from './contact/contact-form/contact-form.component';
import { PinComponent } from './contact/contact-map/pin/pin.component';
import { HoverCardComponent } from './contact/contact-map/hover-card/hover-card.component';
import { DifferentiatorsComponent } from './home/differentiators/differentiators.component';
import { CompanyProfileComponent } from './home/company-profile/company-profile.component';
import { AboutModule } from './about/about.module';
import { ServicesModule } from './servicespage/services.module';
import { SharedModule } from './shared/shared.module';
import { QuoteHeroComponent } from './quote/quote-hero/quote-hero.component';
import { BusinessHoursComponent } from './contact/business-hours/business-hours.component';
import { ContactDropdownComponent } from './contact/contact-dropdown/contact-dropdown.component';









@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        NavbarComponent,
        HomeHeroComponent,
        ContactComponent,
        FooterComponent,
        PrivacyPolicyComponent,
        TermsConditionsComponent,
        ContactHeroComponent,
        FormsComponent,
        ImageMapComponent,
        QuoteComponent,
        ProgressBarComponent,
        QuoteServicesComponent,
        QuoteZipComponent,
        QuoteBusinessInfoComponent,
        QuoteContactInfoComponent,
        ContactMapComponent,
        ContactFormComponent,
        PinComponent,
        HoverCardComponent,
        DifferentiatorsComponent,
        CompanyProfileComponent,
        QuoteHeroComponent,
        BusinessHoursComponent,
        ContactDropdownComponent
        
        
    ],
    exports: [
        ReactiveFormsModule
    ],
    providers: [
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCAPTCHASiteKey },
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RecaptchaV3Module,
        SelectboxGroupComponentModule,
        BrowserAnimationsModule,
        AboutModule,
        ServicesModule,
        SharedModule,
        
    ]
})
export class AppModule { }
