import { Component, OnInit, isDevMode } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-quote-business-info',
  templateUrl: './quote-business-info.component.html',
  styleUrls: ['./quote-business-info.component.scss']
})
export class QuoteBusinessInfoComponent implements OnInit {
  form!: FormGroup
  inDev = isDevMode()
  constructor(private rootFormGroup: FormGroupDirective) {    
  }

  ngOnInit() : void {
    this.form = this.rootFormGroup.control
  }
}
