// service-back-button.component.ts
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-service-back-button',
  template: '<button class="btn btn-custom" (click)="navigateToLink()">{{ linkText }}</button>',
  styleUrls: ['./service-back-button.component.scss']
})
export class ServiceBackButtonComponent {
  @Input() link: string = '';
  @Input() linkText: string = 'Back to Services';

  constructor(private router: Router) {}

  navigateToLink() {
    if (this.link) {
      this.router.navigate([this.link]);
    }
  }
}
