<ng-container [formGroup]="form">


    <div class="container text-center" formGroupName="businessInfo">

        <h2>Industry Name</h2>
        <div class="mb-3">
            <input type="text" formControlName="industryName" class="form-control centered-input mx-auto" placeholder="(example) Healthcare">
            <div *ngIf="form.get('businessInfo')?.get('industryName')?.invalid && (form.get('businessInfo')?.get('industryName')?.dirty || form.get('businessInfo')?.get('industryName')?.touched)"
                class="text-danger">
                <small>Industry Name is required.</small>
            </div>
        </div>

        <h2>Business Name</h2>
        <div class="mb-3">
            <input type="text" formControlName="businessName" class="form-control centered-input mx-auto"
                placeholder="(example) John Smith LLC">
            <div *ngIf="form.get('businessInfo')?.get('businessName')?.invalid && (form.get('businessInfo')?.get('businessName')?.dirty || form.get('businessInfo')?.get('businessName')?.touched)"
                class="text-danger">
                <small>Business Name is required.</small>
            </div>
        </div>

        <h2>Insurance Policy Expiration Date(s) If Applicable</h2>
        <div class="mb-3">
            <input type="text" formControlName="insurancePolicyExpiration" class="form-control centered-input mx-auto" placeholder="YYYY-MM-DD">
        </div>

        <div *ngIf="inDev">
            <h2>Form Page Values</h2>
            <div>{{form}}</div>
            <div *ngIf="form.get('businessInfo')?.valueChanges| async as values">
                <div>Values: {{ values | json }}</div>
            </div>
            <div> Valid: {{form.get('businessInfo')?.valid}}</div>
        </div>
    </div>
</ng-container>