import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {
  private formData = {
    selectedOptions: new Set<string>(),
    otherOption: ''
  };

  private formValid = new BehaviorSubject<boolean>(false);

  constructor() { }

  // Update selection
  updateSelection(option: string, isSelected: boolean): void {
    if (isSelected) {
      this.formData.selectedOptions.add(option);
      if (option === 'other') {
        this.validateOtherOption();
      } else {
        this.formValid.next(true);
      }
    } else {
      this.formData.selectedOptions.delete(option);
      if (option === 'other') {
        this.formData.otherOption = '';
        this.formValid.next(this.formData.selectedOptions.size > 0);
      } else {
        this.formValid.next(this.formData.selectedOptions.size > 0);
      }
    }
  }

  // Update 'other' option text
  updateOtherOption(text: string): void {
    this.formData.otherOption = text;
    this.validateOtherOption();
  }

  // In quote.service.ts
  updateFormValidity(isValid: boolean) {
    this.formValid.next(isValid);
  }


  // Validate 'other' option
  private validateOtherOption(): void {
    const isValid = this.formData.otherOption.trim().length > 0;
    this.formValid.next(isValid);
  }

  // Observable for form validity
  getFormValidity() {
    return this.formValid.asObservable();
  }
}
