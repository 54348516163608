import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-home-hero',
  templateUrl: './home-hero.component.html',
  styleUrls: ['./home-hero.component.scss']
})
export class HomeHeroComponent {
  // Property to hold the opacity level
  learnMoreOpacity: number = 1;

  constructor() { }

  // HostListener to listen to window scroll events
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    // Get the current scroll position
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    // Get the window height
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    // Calculate the scroll percentage (halfway would be 0.5)
    const scrollPercentage = scrollPosition / windowHeight;

    // Adjust the learnMoreOpacity based on the scroll percentage
    // The text should completely fade out when the user has scrolled halfway down the page
    this.learnMoreOpacity = 1 - Math.min(scrollPercentage / 0.5, 1);
  }

  // Method to scroll to content
  scrollToContent(): void {
    // Find the element you want to scroll to
    // This could be another section of your page identified by a unique ID or class
    const contentToScrollTo = document.getElementById('learn-more-target'); // Replace 'contentId' with the actual ID of the target element
    
    // Use scrollIntoView with smooth behavior
    contentToScrollTo?.scrollIntoView({ behavior: 'smooth' });
  }
}
