<!--https://preview.colorlib.com/theme/bft/bootstrap-footer-01/-->
<footer class="bg-dark mt-5">
    <footer class="footer-20192">
        <div class="site-section">
            <div class="container">
                <div class="cta d-block d-md-flex align-items-center px-5">
                    <div>
                        <h2 class="mb-0">Your future deserves protection</h2>
                        <h3 class="text-dark">Start now!</h3>
                    </div>
                    <div class="ms-auto">
                        <a routerLink="/quote" class="btn btn-light rounded py-3 px-5" (click)="scrollToTop()">Get a Quote</a>

                    </div>
                </div>
                <div class="row">
                    <div class="col-sm">
                        <a routerLink="/" class="footer-logo">Perlas Insurance</a>
                        <p class="copyright">
                            <small>© 2024</small>
                        </p>
                    </div>
                    <div class="col-sm">
                        <h3>About Us</h3>
                        <ul class="list-unstyled links">
                            <li><a [routerLink]="'/about'" (click)="scrollToTop()">About</a></li>
                            <li><a [routerLink]="'/about/meet-the-ceo'" (click)="scrollToTop()">Meet The CEO</a></li>
                            <li><a [routerLink]="'/contact'" (click)="scrollToTop()">Contact</a></li>
                        </ul>
                    </div>
                    <div class="col-sm">
                        <h3><a routerLink="/services" class="text-white">Services</a></h3>
                        <ul class="list-unstyled links">
                            <li><a [routerLink]="'services/workerscomp'" (click)="scrollToTop()">Workers Compensation</a></li>
                            <li><a [routerLink]="'services//generalprof'" (click)="scrollToTop()">General and Professional Liability</a></li>
                            <li><a [routerLink]="'services//commercialproperty'" (click)="scrollToTop()">Commercial Property</a></li>
                            <li><a [routerLink]="'services//epli'" (click)="scrollToTop()">Employment Practices Liability</a></li>                       
                        </ul>
                    </div>
                    <div class="col-sm">
                        <h3>Further Information</h3>
                        <ul class="list-unstyled links">
                            <li><a [routerLink]="'/privacy'" (click)="scrollToTop()">Privacy Policy</a></li>
                            <li><a>Photo Credits by Freepik</a></li>
                        </ul>
                    </div>
                    <div class="col-md-3">
                        <h3>Follow us</h3>
                        <ul class="list-unstyled social">
                          <li><a href="https://www.facebook.com/Perlas.Insurance.Services/" target="_blank"><span class="fab fa-facebook"></span></a></li>
                          <li><a href="https://twitter.com/PerlasInsurance" target="_blank"><span class="fab fa-twitter"></span></a></li>
                          <li><a href="https://www.linkedin.com/company/perlas-insurance" target="_blank"><span class="fab fa-linkedin"></span></a></li>
                        </ul>                          
                      </div>
                      
                </div>
            </div>
        </div>
    </footer>

</footer>