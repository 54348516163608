
<div class="quote-body">
  <app-quote-hero></app-quote-hero>
  <div class="quote-form-container">
    <app-progress-bar [currentPage]="currentPage" [totalNumberOfPages]="totalPages"></app-progress-bar>
    <div class="form-inputs my-3" *ngIf="!submissionSuccessMessage">
      <form [formGroup]="rootForm">
        <app-quote-services *ngIf="currentPage === 0"></app-quote-services>
        <app-quote-zip *ngIf="currentPage === 1"></app-quote-zip>
        <app-quote-business-info *ngIf="currentPage === 2"></app-quote-business-info>
        <app-quote-contact-info *ngIf="currentPage === 3"></app-quote-contact-info>
      </form>
    </div>

    <div class="navigation-buttons text-center" *ngIf="!submissionSuccessMessage">
      <button class="btn btn-primary me-2 nav-button" (click)="goToPreviousPage()" *ngIf="currentPage > 0" [disabled]="submissionLoading">Previous</button>
      <button class="btn btn-primary nav-button" (click)="goToNextPage()" *ngIf="currentPage < totalPages - 1" [disabled]="!isCurrentPageValid()">Next</button>
      <button class="btn btn-success nav-button" (click)="onSubmit()" *ngIf="currentPage === totalPages - 1" [disabled]="!rootForm.valid || submissionLoading">Submit</button>
    </div>
    <div class="text-center">
      <div id="loading" *ngIf="submissionLoading"></div>
    </div>
    
    <!-- Success Message -->
    <div *ngIf="submissionSuccessMessage" class="alert alert-success my-5">
        {{ submissionSuccessMessage }}
    </div>
    <div *ngIf="submissionSuccessMessage" class="text-center">
      <h2>
        We will reach out to you shortly to discuss your quote. Thank you!
      </h2>
    </div>

    <!-- Error Message -->
    <div *ngIf="submissionFailureMessage" class="alert alert-danger">
        {{ submissionFailureMessage }}
    </div>
    <div *ngIf="inDev">
      <h2>Form Parent Values</h2>
      <div>{{rootForm}}</div>
      <div *ngIf="rootForm.valueChanges| async as values">
        <div>Values: {{ values | json }}</div>
      </div>
      <div> Valid: {{rootForm.valid}}</div>
    </div>
  </div>
</div>




