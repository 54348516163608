<!-- business-hours.component.html -->

<div class="business-hours p-3 text-white">
    <h2>Office Hours</h2>
    <div class="text-center fs-5">
        <p><strong>MONDAY - FRIDAY</strong> <br>9:00 AM - 5:00 PM*</p>
        <p><strong>SATURDAY - SUNDAY</strong> <br> CLOSED</p>
    </div>
    <div class="text-center fs-6">
        *Pacific Time
    </div>
</div>

