import { HttpHeaders } from "@angular/common/http";

export const environment = {
  // Prod environment config
  production: false,
  contactFormUrl: 'https://qvtc8z7ned.execute-api.us-east-1.amazonaws.com/prod/submit',
  reCAPTCHASiteKey: '6LcBqTQpAAAAAN8YqYk6Ya98qng5OkGaBp0eMCrh',
  HTTP_OPTIONS: {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  }
};
