<!-- app-services-card.component.html -->
<div class="col">
  <div class="card card-div h-100 d-flex flex-column card-hover border-2 rounded">
    <div class="card-body d-flex flex-column align-items-center justify-content-center">
      <h4 class="card-title font-weight-bold fs-3" style="text-align: center; min-height: 100px;" [style]="'min-height:'+ header_min">{{header}}</h4>

      <div *ngIf="icon" class="mt-3">
        <i [class]="icon" style="color: #ffffff; font-size: 75px;"></i>
      </div>
      <div *ngIf="image" class="mt-3">
        <img [src]="image" alt="Image" style="max-width: 100%; max-height: 100px;">
      </div>
      <div *ngIf="description" class="mt-3" [innerHTML]="description" style="font-size: 20px;"></div>
      <div *ngIf="!download" class="mt-3">
        <!-- <a class="btn btn-custom" (click)="openLinkInNewTab(link)">{{linkText}}</a> -->
        <a class="btn btn-custom" (click)="openLinkInCurrentTab(link)">{{ linkText }}</a>

      </div>
      
      <div *ngIf="download" class="mt-4">
        <!-- Use a link to trigger the download -->
        <a class="btn btn-custom" [href]="link" download="{{linkText}}.pdf">{{linkText}}</a>
      </div>
    </div>
  </div>
</div>
