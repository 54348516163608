import { Component } from '@angular/core';

@Component({
  selector: 'app-business-hours',
  templateUrl: './business-hours.component.html',
  styleUrls: ['./business-hours.component.scss']
})
export class BusinessHoursComponent {
  currentDay: string;
  businessHours: { [key: string]: string };

  constructor() {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const now = new Date();
    this.currentDay = daysOfWeek[now.getDay()];

    // Define your business hours here
    this.businessHours = {
      'Monday': '9am - 5pm',
      'Tuesday': '9am - 5pm',
      'Wednesday': '9am - 5pm',
      'Thursday': '9am - 5pm',
      'Friday': '9am - 5pm',
      'Saturday': '10am - 2pm',
      'Sunday': 'Closed'
    };
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }
}
