<div class="col">
    <div class="card card-div h-100 d-flex flex-column card-hover border-2 rounded" 
        [class.selected]="checkboxGroup.isSelected(value)"
        (click)="checkboxGroup.toggleValue(value)">
        <div class="card-body text-center">
            <h5 class="card-title mb-2">{{ header }}</h5>
            <div *ngIf="checkboxGroup.isSelected(value)" class="mt-3" style="font-size: 50px;">
                <i class="fas fa-check-circle"></i></div>
            <i *ngIf="!checkboxGroup.isSelected(value)" [class]="icon" class="mt-3" style="font-size: 50px;"></i>
            <!--Custom option input-->
            <ng-content *ngIf="custom">
                
            </ng-content>
            
            <!--Description for non-optional classes-->
            <p *ngIf="!custom" class="card-text mt-3">{{ description }}</p>
        </div>
    </div>
</div>