import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicesCardComponent } from './services-card/services-card.component';
import { ServiceBackButtonComponent } from './service-back-button/service-back-button.component';


@NgModule({
  declarations: [ServicesCardComponent, ServiceBackButtonComponent],
  imports: [CommonModule],
  exports: [ServicesCardComponent, ServiceBackButtonComponent] // Export it so other modules can use it

})
export class SharedModule { }
