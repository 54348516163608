
<div class="p-5 m-5">

</div>
<div class="container">
    <div data-custom-class="body">
        <div>
            <strong><span style="font-size: 26px;"><span data-custom-class="title">PRIVACY POLICY</span></span></strong>
        </div>
        <div>
            <br>
        </div>
        <div>
            <span style="color: rgb(127, 127, 127);"><strong><span style="font-size: 15px;"><span
                            data-custom-class="subtitle">Last updated October 31, 2023</span></span></strong></span>
        </div>
        <div>
            <br>
        </div>
        <div>
            <br>
        </div>
        <div>
            <br>
        </div>
        <div style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                        data-custom-class="body_text">This privacy notice for Perlas Group, Inc. (doing business as
                        Perlas Insurance Services) ("<strong>we</strong>," "<strong>us</strong>," or
                        "<strong>our</strong>"</span><span data-custom-class="body_text">), describes how and why we
                        might collect, store, use, and/or share ("<strong>process</strong>") your information when you
                        use our services ("<strong>Services</strong>"), such as when you:</span></span></span>
        </div>
        <ul>
            <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">Visit our
                            website at <a href="http://www.perlasinsurance.com" target="_blank"
                                data-custom-class="link">http://www.perlasinsurance.com</a><span
                                style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                        data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                style="color: rgb(89, 89, 89);">, or any website of ours that links to
                                                this privacy
                                                notice</span></span></span></span></span></span></span></span></li>
        </ul>
        <div>
            <div style="line-height: 1.5;"></div>
            <ul>
                <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">Engage
                                with us in other related ways, including any sales, marketing, or
                                events</span></span></span></li>
            </ul>
            <div style="line-height: 1.5;">
                <span style="font-size: 15px;"><span style="color: rgb(127, 127, 127);"><span
                            data-custom-class="body_text"><strong>Questions or concerns?&nbsp;</strong>Reading this
                            privacy notice will help you understand your privacy rights and choices. If you do not agree
                            with our policies and practices, please do not use our Services. If you still have any
                            questions or concerns, please contact us at
                            jpatrick&#64;perlasinsurance.com.</span></span></span>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div style="line-height: 1.5;">
                <strong><span style="font-size: 15px;"><span data-custom-class="heading_1">SUMMARY OF KEY
                            POINTS</span></span></strong>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div style="line-height: 1.5;">
                <span style="font-size: 15px;"><span data-custom-class="body_text"><strong><em>This summary provides key
                                points from our privacy notice, but you can find out more details about any of these
                                topics by clicking the link following each key point or by using
                                our&nbsp;</em></strong></span></span><a data-custom-class="link" (click)="scrollTo('toc')"><span
                        style="font-size: 15px;"><span data-custom-class="body_text"><strong><em>table of
                                    contents</em></strong></span></span></a><span style="font-size: 15px;"><span
                        data-custom-class="body_text"><strong><em>&nbsp;below to find the section you are looking
                                for.</em></strong></span></span>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div style="line-height: 1.5;">
                <span style="font-size: 15px;"><span data-custom-class="body_text"><strong>What personal information do
                            we process?</strong> When you visit, use, or navigate our Services, we may process personal
                        information depending on how you interact with us and the Services, the choices you make, and
                        the products and features you use. Learn more about&nbsp;</span></span><a
                    data-custom-class="link" (click)="scrollTo('personalinfo')"><span style="font-size: 15px;"><span
                            data-custom-class="body_text">personal information you disclose to us</span></span></a><span
                    data-custom-class="body_text">.</span>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div style="line-height: 1.5;">
                <span style="font-size: 15px;"><span data-custom-class="body_text"><strong>Do we process any sensitive
                            personal information?</strong> We may process sensitive personal information when necessary
                        with your consent or as otherwise permitted by applicable law. Learn more
                        about&nbsp;</span></span><a data-custom-class="link" (click)="scrollTo('sensitiveinfo')"><span
                        style="font-size: 15px;"><span data-custom-class="body_text">sensitive information we
                            process</span></span></a><span data-custom-class="body_text">.</span>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div style="line-height: 1.5;">
                <span style="font-size: 15px;"><span data-custom-class="body_text"><strong>Do we receive any information
                            from third parties?</strong> We do not receive any information from third
                        parties.</span></span>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div style="line-height: 1.5;">
                <span style="font-size: 15px;"><span data-custom-class="body_text"><strong>How do we process your
                            information?</strong> We process your information to provide, improve, and administer our
                        Services, communicate with you, for security and fraud prevention, and to comply with law. We
                        may also process your information for other purposes with your consent. We process your
                        information only when we have a valid legal reason to do so. Learn more
                        about&nbsp;</span></span><u><span (click)="scrollTo('infouse')">how we process your
                            information</span></u><span>.</span>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div style="line-height: 1.5;">
                <span style="font-size: 15px;"><span data-custom-class="body_text"><strong>In what situations and with
                            which parties do we share personal information?</strong> We may share information in
                        specific situations and with specific third parties. Learn more about&nbsp;</span></span><a
                    data-custom-class="link" (click)="scrollTo('whoshare')"><span style="font-size: 15px;"><span
                            data-custom-class="body_text">when and with whom we share your personal
                            information</span></span></a><span style="font-size: 15px;"><span
                        data-custom-class="body_text">.</span></span>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div style="line-height: 1.5;">
                <span style="font-size: 15px;"><span data-custom-class="body_text"><strong>How do we keep your
                            information safe?</strong> We have organizational and technical processes and procedures in
                        place to protect your personal information. However, no electronic transmission over the
                        internet or information storage technology can be guaranteed to be 100% secure, so we cannot
                        promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not
                        be able to defeat our security and improperly collect, access, steal, or modify your
                        information. Learn more about&nbsp;</span></span><a data-custom-class="link"
                        (click)="scrollTo('infosafe')"><span style="font-size: 15px;"><span data-custom-class="body_text">how we keep your
                            information safe</span></span></a><span data-custom-class="body_text">.</span>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div style="line-height: 1.5;">
                <span style="font-size: 15px;"><span data-custom-class="body_text"><strong>What are your
                            rights?</strong> Depending on where you are located geographically, the applicable privacy
                        law may mean you have certain rights regarding your personal information. Learn more
                        about&nbsp;</span></span><a data-custom-class="link" (click)="scrollTo('privacyrights')"><span
                        style="font-size: 15px;"><span data-custom-class="body_text">your privacy
                            rights</span></span></a><span data-custom-class="body_text">.</span>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div style="line-height: 1.5;">
                <span style="font-size: 15px;"><span data-custom-class="body_text"><strong>How do you exercise your
                            rights?</strong> The easiest way to exercise your rights is by submitting
                        a&nbsp;</span></span><a data-custom-class="link"
                    href="https://app.termly.io/notify/cde31c17-9fe4-4f4b-a6c1-2d7b14159a68" rel="noopener noreferrer"
                    target="_blank"><span style="font-size: 15px;"><span data-custom-class="body_text">data subject
                            access request</span></span></a><span style="font-size: 15px;"><span
                        data-custom-class="body_text">, or by contacting us. We will consider and act upon any request
                        in accordance with applicable data protection laws.</span></span>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div style="line-height: 1.5;">
                <span style="font-size: 15px;"><span data-custom-class="body_text">Want to learn more about what we do
                        with any information we collect?&nbsp;</span></span><a data-custom-class="link"
                        (click)="scrollTo('toc')"><span style="font-size: 15px;"><span data-custom-class="body_text">Review the privacy
                            notice in full</span></span></a><span style="font-size: 15px;"><span
                        data-custom-class="body_text">.</span></span>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div id="toc" style="line-height: 1.5;">
                <span style="font-size: 15px;"><span style="color: rgb(127, 127, 127);"><span
                            style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">TABLE OF
                                    CONTENTS</span></strong></span></span></span>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div style="line-height: 1.5;">
                <u><span style="font-size: 15px;"><a data-custom-class="link" (click)="scrollTo('infocollect')"><span
                            style="color: rgb(89, 89, 89);">1. WHAT INFORMATION DO WE COLLECT?</span></a></span></u>
            </div>
            <div style="line-height: 1.5;">
                <u><span style="font-size: 15px;"><a data-custom-class="link" (click)="scrollTo('infouse')"><span
                            style="color: rgb(89, 89, 89);">2. HOW DO WE PROCESS YOUR INFORMATION?</span></a></span></u>
            </div>
            <div style="line-height: 1.5;">
                <u><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><a data-custom-class="link" (click)="scrollTo('whoshare')"
                            >3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                            INFORMATION?</a></span></span></u>
            </div>
            <div style="line-height: 1.5;">
                <u (click)="scrollTo('inforetain')"><span style="font-size: 15px;"><a data-custom-class="link" ><span
                            style="color: rgb(89, 89, 89);">4. HOW LONG DO WE KEEP YOUR INFORMATION?</span></a></span></u>
            </div>
            <div style="line-height: 1.5;">
                <u><span style="font-size: 15px;"><a data-custom-class="link" (click)="scrollTo('infosafe')"><span
                            style="color: rgb(89, 89, 89);">5. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></a></span></u>
            </div>
            <div style="line-height: 1.5;">
                <u><span style="font-size: 15px;"><a data-custom-class="link" (click)="scrollTo('infominors')"><span
                            style="color: rgb(89, 89, 89);">6. DO WE COLLECT INFORMATION FROM MINORS?</span></a></span></u>
            </div>
            <div style="line-height: 1.5;">
                <u><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);" (click)="scrollTo('privacyrights')"><a data-custom-class="link"
                            >7. WHAT ARE YOUR PRIVACY RIGHTS?</a></span></span></u>
            </div>
            <div style="line-height: 1.5;">
                <u><span style="font-size: 15px;"><a data-custom-class="link" (click)="scrollTo('DNT')"><span
                            style="color: rgb(89, 89, 89);">8. CONTROLS FOR DO-NOT-TRACK FEATURES</span></a></span></u>
            </div>
            <div style="line-height: 1.5;">
                <u><span style="font-size: 15px;"><a data-custom-class="link" (click)="scrollTo('uslaws')"><span
                            style="color: rgb(89, 89, 89);">9. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY
                            RIGHTS?</span></a></span></u>
            </div>
            <div style="line-height: 1.5;"></div>
            <div style="line-height: 1.5;">
                <u><span style="font-size: 15px;"><a data-custom-class="link" (click)="scrollTo('policyupdates')"><span
                            style="color: rgb(89, 89, 89);">10. DO WE MAKE UPDATES TO THIS NOTICE?</span></a></span></u>
            </div>
            <div style="line-height: 1.5;">
                <u><a data-custom-class="link" (click)="scrollTo('contact')"><span style="color: rgb(89, 89, 89); font-size: 15px;">11.
                        HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></a></u>
            </div>
            <div style="line-height: 1.5;">
                <u><a data-custom-class="link" (click)="scrollTo('request')"><span style="color: rgb(89, 89, 89);">12. HOW CAN YOU
                        REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></a></u>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div id="infocollect" style="line-height: 1.5;">
                <span style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                    style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">1. WHAT
                                            INFORMATION DO WE
                                            COLLECT?</span></strong></span></span></span></span></span>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div id="personalinfo" style="line-height: 1.5;">
                <span data-custom-class="heading_2" style="color: rgb(0, 0, 0);"><span
                        style="font-size: 15px;"><strong>Personal information you disclose to us</strong></span></span>
            </div>
            <div>
                <div>
                    <br>
                </div>
                <div style="line-height: 1.5;">
                    <span style="color: rgb(127, 127, 127);"><span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text"><strong><em>In
                                                    Short:</em></strong></span></span></span></span><span
                                data-custom-class="body_text"><span
                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text"><strong><em>&nbsp;</em></strong><em>We collect
                                                personal information that you provide to
                                                us.</em></span></span></span></span></span></span>
                </div>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div style="line-height: 1.5;">
                <span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">We collect
                            personal information that you voluntarily provide to us when you</span> <span
                            data-custom-class="body_text">express an interest in obtaining information about us or our
                            products and Services, when you participate in activities on the Services, or otherwise when
                            you contact us.</span></span></span>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div style="line-height: 1.5;"></div>
            <div style="line-height: 1.5;">
                <span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            data-custom-class="body_text"><strong>Personal Information Provided by You.</strong> The
                            personal information that we collect depends on the context of your interactions with us and
                            the Services, the choices you make, and the products and features you use. The personal
                            information we collect may include the following:</span></span></span>
            </div>
            <ul>
                <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                    style="font-size: 15px;"><span
                                        data-custom-class="body_text">names</span></span></span></span></span></li>
            </ul>
            <div style="line-height: 1.5;"></div>
            <ul>
                <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                    style="font-size: 15px;"><span data-custom-class="body_text">phone
                                        numbers</span></span></span></span></span></li>
            </ul>
            <div style="line-height: 1.5;"></div>
            <ul>
                <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                    style="font-size: 15px;"><span data-custom-class="body_text">email
                                        addresses</span></span></span></span></span></li>
            </ul>
            <div style="line-height: 1.5;"></div>
            <ul>
                <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                    style="font-size: 15px;"><span data-custom-class="body_text">mailing
                                        addresses</span></span></span></span></span></li>
            </ul>
            <div style="line-height: 1.5;"></div>
            <ul>
                <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                    style="font-size: 15px;"><span data-custom-class="body_text">contact
                                        preferences</span></span></span></span></span></li>
            </ul>
            <div style="line-height: 1.5;"></div>
            <ul>
                <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                    style="font-size: 15px;"><span data-custom-class="body_text">contact or
                                        authentication data</span></span></span></span></span></li>
            </ul>
            <div style="line-height: 1.5;"></div>
            <ul>
                <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                    style="font-size: 15px;"><span data-custom-class="body_text">job
                                        titles</span></span></span></span></span></li>
            </ul>
            <div style="line-height: 1.5;"></div>
            <ul>
                <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                    style="font-size: 15px;"><span data-custom-class="body_text">billing
                                        addresses</span></span></span></span></span></li>
            </ul>
            <div style="line-height: 1.5;"></div>
            <div id="sensitiveinfo" style="line-height: 1.5;">
                <span style="font-size: 15px;"><span data-custom-class="body_text"><strong>Sensitive
                            Information.</strong> When necessary, with your consent or as otherwise permitted by
                        applicable law, we process the following categories of sensitive information:</span></span>
            </div>
            <ul>
                <li style="line-height: 1.5;"><span style="font-size: 15px;"><span
                            data-custom-class="body_text">financial data</span></span></li>
            </ul>
            <div style="line-height: 1.5;">
                <span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">All
                            personal information that you provide to us must be true, complete, and accurate, and you
                            must notify us of any changes to such personal information.</span></span></span>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div style="line-height: 1.5;"></div>
            <div id="infouse" style="line-height: 1.5;">
                <span style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                    style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">2. HOW DO
                                            WE PROCESS YOUR
                                            INFORMATION?</span></strong></span></span></span></span></span>
            </div>
            <div>
                <div style="line-height: 1.5;">
                    <br>
                </div>
                <div style="line-height: 1.5;">
                    <span style="color: rgb(127, 127, 127);"><span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text"><strong><em>In
                                                    Short:&nbsp;</em></strong><em>We process your information to
                                                provide, improve, and administer our Services, communicate with you, for
                                                security and fraud prevention, and to comply with law. We may also
                                                process your information for other purposes with your
                                                consent.</em></span></span></span></span></span></span>
                </div>
            </div>
            <div style="line-height: 1.5;">
                <br>
            </div>
            <div style="line-height: 1.5;">
                <span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>We
                                process your personal information for a variety of reasons, depending on how you
                                interact with our Services, including:</strong></span></span></span>
                <div style="line-height: 1.5;"></div>
                <ul>
                    <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                    data-custom-class="body_text"><strong>To deliver and facilitate delivery of services
                                        to the user.&nbsp;</strong>We may process your information to provide you with
                                    the requested service.</span></span></span></li>
                </ul>
                <div style="line-height: 1.5;">
                    <div style="line-height: 1.5;"></div>
                    <ul>
                        <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        data-custom-class="body_text"><strong>To respond to user inquiries/offer support
                                            to users.&nbsp;</strong>We may process your information to respond to your
                                        inquiries and solve any potential issues you might have with the requested
                                        service.</span></span></span></li>
                    </ul>
                    <div style="line-height: 1.5;">
                        <div style="line-height: 1.5;">
                            <div style="line-height: 1.5;">
                                <div style="line-height: 1.5;"></div>
                                <ul>
                                    <li style="line-height: 1.5;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                    data-custom-class="body_text"><strong>To fulfill and manage your
                                                        orders.&nbsp;</strong>We may process your information to fulfill
                                                    and manage your orders, payments, returns, and exchanges made
                                                    through the Services.</span></span></span></li>
                                </ul>
                                <div style="line-height: 1.5;">
                                    <p style="font-size: 15px; line-height: 1.5;"></p>
                                    <p style="font-size: 15px; line-height: 1.5;"></p>
                                    <p style="font-size: 15px; line-height: 1.5;"></p>
                                    <p style="font-size: 15px; line-height: 1.5;"></p>
                                    <div style="line-height: 1.5;">
                                        <div style="line-height: 1.5;">
                                            <div style="line-height: 1.5;">
                                                <div style="line-height: 1.5;">
                                                    <div style="line-height: 1.5;">
                                                        <div style="line-height: 1.5;">
                                                            <div style="line-height: 1.5;">
                                                                <div style="line-height: 1.5;">
                                                                    <div style="line-height: 1.5;"></div>
                                                                    <ul>
                                                                        <li style="line-height: 1.5;"><span
                                                                                data-custom-class="body_text"><span
                                                                                    style="font-size: 15px;"><strong>To
                                                                                        evaluate and improve our
                                                                                        Services, products, marketing,
                                                                                        and your experience.</strong> We
                                                                                    may process your information when we
                                                                                    believe it is necessary to identify
                                                                                    usage trends, determine the
                                                                                    effectiveness of our promotional
                                                                                    campaigns, and to evaluate and
                                                                                    improve our Services, products,
                                                                                    marketing, and your
                                                                                    experience.</span></span></li>
                                                                    </ul>
                                                                    <div style="line-height: 1.5;"></div>
                                                                    <ul>
                                                                        <li style="line-height: 1.5;"><span
                                                                                data-custom-class="body_text"><span
                                                                                    style="font-size: 15px;"><strong>To
                                                                                        identify usage trends.</strong>
                                                                                    We may process information about how
                                                                                    you use our Services to better
                                                                                    understand how they are being used
                                                                                    so we can improve
                                                                                    them.</span></span></li>
                                                                    </ul>
                                                                    <div style="line-height: 1.5;">
                                                                        <div style="line-height: 1.5;">
                                                                            <div style="line-height: 1.5;">
                                                                                <div style="line-height: 1.5;"></div>
                                                                                <ul>
                                                                                    <li style="line-height: 1.5;"><span
                                                                                            style="font-size: 15px;"><span
                                                                                                data-custom-class="body_text"><strong>To
                                                                                                    comply with our
                                                                                                    legal
                                                                                                    obligations.</strong>
                                                                                                We may process your
                                                                                                information to comply
                                                                                                with our legal
                                                                                                obligations, respond to
                                                                                                legal requests, and
                                                                                                exercise, establish, or
                                                                                                defend our legal
                                                                                                rights.</span></span>
                                                                                    </li>
                                                                                </ul>
                                                                                <div style="line-height: 1.5;">
                                                                                    <div style="line-height: 1.5;">
                                                                                    </div>
                                                                                    <div style="line-height: 1.5;">
                                                                                        <br>
                                                                                    </div>
                                                                                    <div id="whoshare"
                                                                                        style="line-height: 1.5;">
                                                                                        <span
                                                                                            style="color: rgb(127, 127, 127);"><span
                                                                                                style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                            id="control"
                                                                                                            style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                    data-custom-class="heading_1">3.
                                                                                                                    WHEN
                                                                                                                    AND
                                                                                                                    WITH
                                                                                                                    WHOM
                                                                                                                    DO
                                                                                                                    WE
                                                                                                                    SHARE
                                                                                                                    YOUR
                                                                                                                    PERSONAL
                                                                                                                    INFORMATION?</span></strong></span></span></span></span></span>
                                                                                    </div>
                                                                                    <div style="line-height: 1.5;">
                                                                                        <br>
                                                                                    </div>
                                                                                    <div style="line-height: 1.5;">
                                                                                        <span
                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                    data-custom-class="body_text"><strong><em>In
                                                                                                            Short:</em></strong><em>&nbsp;We
                                                                                                        may share
                                                                                                        information in
                                                                                                        specific
                                                                                                        situations
                                                                                                        described in
                                                                                                        this section
                                                                                                        and/or with the
                                                                                                        following third
                                                                                                        parties.</em></span></span>&nbsp;</span>
                                                                                    </div>
                                                                                    <div style="line-height: 1.5;">
                                                                                    </div>
                                                                                    <div style="line-height: 1.5;">
                                                                                        <br>
                                                                                    </div>
                                                                                    <div style="line-height: 1.5;">
                                                                                        <span
                                                                                            style="font-size: 15px;"><span
                                                                                                data-custom-class="body_text">We
                                                                                                may need to share your
                                                                                                personal information in
                                                                                                the following
                                                                                                situations:</span></span>
                                                                                    </div>
                                                                                    <ul>
                                                                                        <li style="line-height: 1.5;">
                                                                                            <span
                                                                                                style="font-size: 15px;"><span
                                                                                                    data-custom-class="body_text"><strong>Business
                                                                                                        Transfers.</strong>
                                                                                                    We may share or
                                                                                                    transfer your
                                                                                                    information in
                                                                                                    connection with, or
                                                                                                    during negotiations
                                                                                                    of, any merger, sale
                                                                                                    of company assets,
                                                                                                    financing, or
                                                                                                    acquisition of all
                                                                                                    or a portion of our
                                                                                                    business to another
                                                                                                    company.</span></span>
                                                                                        </li>
                                                                                    </ul>
                                                                                    <div style="line-height: 1.5;">
                                                                                        <div style="line-height: 1.5;">
                                                                                            <div
                                                                                                style="line-height: 1.5;">
                                                                                                <div
                                                                                                    style="line-height: 1.5;">
                                                                                                    <div
                                                                                                        style="line-height: 1.5;">
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div id="inforetain"
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                style="color: rgb(127, 127, 127);"><span
                                                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                id="control"
                                                                                                                                style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                        data-custom-class="heading_1">4.
                                                                                                                                        HOW
                                                                                                                                        LONG
                                                                                                                                        DO
                                                                                                                                        WE
                                                                                                                                        KEEP
                                                                                                                                        YOUR
                                                                                                                                        INFORMATION?</span></strong></span></span></span></span></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                        data-custom-class="body_text"><strong><em>In
                                                                                                                                Short:&nbsp;</em></strong><em>We
                                                                                                                            keep
                                                                                                                            your
                                                                                                                            information
                                                                                                                            for
                                                                                                                            as
                                                                                                                            long
                                                                                                                            as
                                                                                                                            necessary
                                                                                                                            to
                                                                                                                            fulfill
                                                                                                                            the
                                                                                                                            purposes
                                                                                                                            outlined
                                                                                                                            in
                                                                                                                            this
                                                                                                                            privacy
                                                                                                                            notice
                                                                                                                            unless
                                                                                                                            otherwise
                                                                                                                            required
                                                                                                                            by
                                                                                                                            law.</em></span></span></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                        data-custom-class="body_text">We
                                                                                                                        will
                                                                                                                        only
                                                                                                                        keep
                                                                                                                        your
                                                                                                                        personal
                                                                                                                        information
                                                                                                                        for
                                                                                                                        as
                                                                                                                        long
                                                                                                                        as
                                                                                                                        it
                                                                                                                        is
                                                                                                                        necessary
                                                                                                                        for
                                                                                                                        the
                                                                                                                        purposes
                                                                                                                        set
                                                                                                                        out
                                                                                                                        in
                                                                                                                        this
                                                                                                                        privacy
                                                                                                                        notice,
                                                                                                                        unless
                                                                                                                        a
                                                                                                                        longer
                                                                                                                        retention
                                                                                                                        period
                                                                                                                        is
                                                                                                                        required
                                                                                                                        or
                                                                                                                        permitted
                                                                                                                        by
                                                                                                                        law
                                                                                                                        (such
                                                                                                                        as
                                                                                                                        tax,
                                                                                                                        accounting,
                                                                                                                        or
                                                                                                                        other
                                                                                                                        legal
                                                                                                                        requirements).</span></span></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                        data-custom-class="body_text">When
                                                                                                                        we
                                                                                                                        have
                                                                                                                        no
                                                                                                                        ongoing
                                                                                                                        legitimate
                                                                                                                        business
                                                                                                                        need
                                                                                                                        to
                                                                                                                        process
                                                                                                                        your
                                                                                                                        personal
                                                                                                                        information,
                                                                                                                        we
                                                                                                                        will
                                                                                                                        either
                                                                                                                        delete
                                                                                                                        or
                                                                                                                        anonymize
                                                                                                                        such
                                                                                                                        information,
                                                                                                                        or,
                                                                                                                        if
                                                                                                                        this
                                                                                                                        is
                                                                                                                        not
                                                                                                                        possible
                                                                                                                        (for
                                                                                                                        example,
                                                                                                                        because
                                                                                                                        your
                                                                                                                        personal
                                                                                                                        information
                                                                                                                        has
                                                                                                                        been
                                                                                                                        stored
                                                                                                                        in
                                                                                                                        backup
                                                                                                                        archives),
                                                                                                                        then
                                                                                                                        we
                                                                                                                        will
                                                                                                                        securely
                                                                                                                        store
                                                                                                                        your
                                                                                                                        personal
                                                                                                                        information
                                                                                                                        and
                                                                                                                        isolate
                                                                                                                        it
                                                                                                                        from
                                                                                                                        any
                                                                                                                        further
                                                                                                                        processing
                                                                                                                        until
                                                                                                                        deletion
                                                                                                                        is
                                                                                                                        possible.</span></span></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div id="infosafe"
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                style="color: rgb(127, 127, 127);"><span
                                                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                id="control"
                                                                                                                                style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                        data-custom-class="heading_1">5.
                                                                                                                                        HOW
                                                                                                                                        DO
                                                                                                                                        WE
                                                                                                                                        KEEP
                                                                                                                                        YOUR
                                                                                                                                        INFORMATION
                                                                                                                                        SAFE?</span></strong></span></span></span></span></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                        data-custom-class="body_text"><strong><em>In
                                                                                                                                Short:&nbsp;</em></strong><em>We
                                                                                                                            aim
                                                                                                                            to
                                                                                                                            protect
                                                                                                                            your
                                                                                                                            personal
                                                                                                                            information
                                                                                                                            through
                                                                                                                            a
                                                                                                                            system
                                                                                                                            of
                                                                                                                            organizational
                                                                                                                            and
                                                                                                                            technical
                                                                                                                            security
                                                                                                                            measures.</em></span></span></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                        data-custom-class="body_text">We
                                                                                                                        have
                                                                                                                        implemented
                                                                                                                        appropriate
                                                                                                                        and
                                                                                                                        reasonable
                                                                                                                        technical
                                                                                                                        and
                                                                                                                        organizational
                                                                                                                        security
                                                                                                                        measures
                                                                                                                        designed
                                                                                                                        to
                                                                                                                        protect
                                                                                                                        the
                                                                                                                        security
                                                                                                                        of
                                                                                                                        any
                                                                                                                        personal
                                                                                                                        information
                                                                                                                        we
                                                                                                                        process.
                                                                                                                        However,
                                                                                                                        despite
                                                                                                                        our
                                                                                                                        safeguards
                                                                                                                        and
                                                                                                                        efforts
                                                                                                                        to
                                                                                                                        secure
                                                                                                                        your
                                                                                                                        information,
                                                                                                                        no
                                                                                                                        electronic
                                                                                                                        transmission
                                                                                                                        over
                                                                                                                        the
                                                                                                                        Internet
                                                                                                                        or
                                                                                                                        information
                                                                                                                        storage
                                                                                                                        technology
                                                                                                                        can
                                                                                                                        be
                                                                                                                        guaranteed
                                                                                                                        to
                                                                                                                        be
                                                                                                                        100%
                                                                                                                        secure,
                                                                                                                        so
                                                                                                                        we
                                                                                                                        cannot
                                                                                                                        promise
                                                                                                                        or
                                                                                                                        guarantee
                                                                                                                        that
                                                                                                                        hackers,
                                                                                                                        cybercriminals,
                                                                                                                        or
                                                                                                                        other
                                                                                                                        unauthorized
                                                                                                                        third
                                                                                                                        parties
                                                                                                                        will
                                                                                                                        not
                                                                                                                        be
                                                                                                                        able
                                                                                                                        to
                                                                                                                        defeat
                                                                                                                        our
                                                                                                                        security
                                                                                                                        and
                                                                                                                        improperly
                                                                                                                        collect,
                                                                                                                        access,
                                                                                                                        steal,
                                                                                                                        or
                                                                                                                        modify
                                                                                                                        your
                                                                                                                        information.
                                                                                                                        Although
                                                                                                                        we
                                                                                                                        will
                                                                                                                        do
                                                                                                                        our
                                                                                                                        best
                                                                                                                        to
                                                                                                                        protect
                                                                                                                        your
                                                                                                                        personal
                                                                                                                        information,
                                                                                                                        transmission
                                                                                                                        of
                                                                                                                        personal
                                                                                                                        information
                                                                                                                        to
                                                                                                                        and
                                                                                                                        from
                                                                                                                        our
                                                                                                                        Services
                                                                                                                        is
                                                                                                                        at
                                                                                                                        your
                                                                                                                        own
                                                                                                                        risk.
                                                                                                                        You
                                                                                                                        should
                                                                                                                        only
                                                                                                                        access
                                                                                                                        the
                                                                                                                        Services
                                                                                                                        within
                                                                                                                        a
                                                                                                                        secure
                                                                                                                        environment.</span></span></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div id="infominors"
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                style="color: rgb(127, 127, 127);"><span
                                                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                id="control"
                                                                                                                                style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                        data-custom-class="heading_1">6.
                                                                                                                                        DO
                                                                                                                                        WE
                                                                                                                                        COLLECT
                                                                                                                                        INFORMATION
                                                                                                                                        FROM
                                                                                                                                        MINORS?</span></strong></span></span></span></span></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                        data-custom-class="body_text"><strong><em>In
                                                                                                                                Short:</em></strong><em>&nbsp;We
                                                                                                                            do
                                                                                                                            not
                                                                                                                            knowingly
                                                                                                                            collect
                                                                                                                            data
                                                                                                                            from
                                                                                                                            or
                                                                                                                            market
                                                                                                                            to
                                                                                                                            children
                                                                                                                            under
                                                                                                                            18
                                                                                                                            years
                                                                                                                            of
                                                                                                                            age.</em></span></span></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                        data-custom-class="body_text">We
                                                                                                                        do
                                                                                                                        not
                                                                                                                        knowingly
                                                                                                                        solicit
                                                                                                                        data
                                                                                                                        from
                                                                                                                        or
                                                                                                                        market
                                                                                                                        to
                                                                                                                        children
                                                                                                                        under
                                                                                                                        18
                                                                                                                        years
                                                                                                                        of
                                                                                                                        age.
                                                                                                                        By
                                                                                                                        using
                                                                                                                        the
                                                                                                                        Services,
                                                                                                                        you
                                                                                                                        represent
                                                                                                                        that
                                                                                                                        you
                                                                                                                        are
                                                                                                                        at
                                                                                                                        least
                                                                                                                        18
                                                                                                                        or
                                                                                                                        that
                                                                                                                        you
                                                                                                                        are
                                                                                                                        the
                                                                                                                        parent
                                                                                                                        or
                                                                                                                        guardian
                                                                                                                        of
                                                                                                                        such
                                                                                                                        a
                                                                                                                        minor
                                                                                                                        and
                                                                                                                        consent
                                                                                                                        to
                                                                                                                        such
                                                                                                                        minor
                                                                                                                        dependent’s
                                                                                                                        use
                                                                                                                        of
                                                                                                                        the
                                                                                                                        Services.
                                                                                                                        If
                                                                                                                        we
                                                                                                                        learn
                                                                                                                        that
                                                                                                                        personal
                                                                                                                        information
                                                                                                                        from
                                                                                                                        users
                                                                                                                        less
                                                                                                                        than
                                                                                                                        18
                                                                                                                        years
                                                                                                                        of
                                                                                                                        age
                                                                                                                        has
                                                                                                                        been
                                                                                                                        collected,
                                                                                                                        we
                                                                                                                        will
                                                                                                                        deactivate
                                                                                                                        the
                                                                                                                        account
                                                                                                                        and
                                                                                                                        take
                                                                                                                        reasonable
                                                                                                                        measures
                                                                                                                        to
                                                                                                                        promptly
                                                                                                                        delete
                                                                                                                        such
                                                                                                                        data
                                                                                                                        from
                                                                                                                        our
                                                                                                                        records.
                                                                                                                        If
                                                                                                                        you
                                                                                                                        become
                                                                                                                        aware
                                                                                                                        of
                                                                                                                        any
                                                                                                                        data
                                                                                                                        we
                                                                                                                        may
                                                                                                                        have
                                                                                                                        collected
                                                                                                                        from
                                                                                                                        children
                                                                                                                        under
                                                                                                                        age
                                                                                                                        18,
                                                                                                                        please
                                                                                                                        contact
                                                                                                                        us
                                                                                                                        at
                                                                                                                        <span
                                                                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                data-custom-class="body_text">jpatrick&#64;perlasinsurance.com</span></span>.</span></span></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div id="privacyrights"
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                style="color: rgb(127, 127, 127);"><span
                                                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                id="control"
                                                                                                                                style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                        data-custom-class="heading_1">7.
                                                                                                                                        WHAT
                                                                                                                                        ARE
                                                                                                                                        YOUR
                                                                                                                                        PRIVACY
                                                                                                                                        RIGHTS?</span></strong></span></span></span></span></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                        data-custom-class="body_text"><strong><em>In
                                                                                                                                Short:</em></strong><em>&nbsp;<span
                                                                                                                                style="color: rgb(89, 89, 89);">&nbsp;</span>You
                                                                                                                            may
                                                                                                                            review,
                                                                                                                            change,
                                                                                                                            or
                                                                                                                            terminate
                                                                                                                            your
                                                                                                                            account
                                                                                                                            at
                                                                                                                            any
                                                                                                                            time.</em></span></span></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div id="withdrawconsent"
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                        data-custom-class="body_text"><strong><u>Withdrawing
                                                                                                                                your
                                                                                                                                consent:</u></strong>
                                                                                                                        If
                                                                                                                        we
                                                                                                                        are
                                                                                                                        relying
                                                                                                                        on
                                                                                                                        your
                                                                                                                        consent
                                                                                                                        to
                                                                                                                        process
                                                                                                                        your
                                                                                                                        personal
                                                                                                                        information,
                                                                                                                        which
                                                                                                                        may
                                                                                                                        be
                                                                                                                        express
                                                                                                                        and/or
                                                                                                                        implied
                                                                                                                        consent
                                                                                                                        depending
                                                                                                                        on
                                                                                                                        the
                                                                                                                        applicable
                                                                                                                        law,
                                                                                                                        you
                                                                                                                        have
                                                                                                                        the
                                                                                                                        right
                                                                                                                        to
                                                                                                                        withdraw
                                                                                                                        your
                                                                                                                        consent
                                                                                                                        at
                                                                                                                        any
                                                                                                                        time.
                                                                                                                        You
                                                                                                                        can
                                                                                                                        withdraw
                                                                                                                        your
                                                                                                                        consent
                                                                                                                        at
                                                                                                                        any
                                                                                                                        time
                                                                                                                        by
                                                                                                                        contacting
                                                                                                                        us
                                                                                                                        by
                                                                                                                        using
                                                                                                                        the
                                                                                                                        contact
                                                                                                                        details
                                                                                                                        provided
                                                                                                                        in
                                                                                                                        the
                                                                                                                        section
                                                                                                                        "</span></span></span><a
                                                                                                                data-custom-class="link"
                                                                                                                (click)="scrollTo('contact')"><span
                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                            data-custom-class="body_text">HOW
                                                                                                                            CAN
                                                                                                                            YOU
                                                                                                                            CONTACT
                                                                                                                            US
                                                                                                                            ABOUT
                                                                                                                            THIS
                                                                                                                            NOTICE?</span></span></span></a><span
                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                        data-custom-class="body_text">"
                                                                                                                        below.</span></span></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                style="font-size: 15px;"><span
                                                                                                                    data-custom-class="body_text">However,
                                                                                                                    please
                                                                                                                    note
                                                                                                                    that
                                                                                                                    this
                                                                                                                    will
                                                                                                                    not
                                                                                                                    affect
                                                                                                                    the
                                                                                                                    lawfulness
                                                                                                                    of
                                                                                                                    the
                                                                                                                    processing
                                                                                                                    before
                                                                                                                    its
                                                                                                                    withdrawal
                                                                                                                    nor,
                                                                                                                    when
                                                                                                                    applicable
                                                                                                                    law
                                                                                                                    allows,
                                                                                                                    will
                                                                                                                    it
                                                                                                                    affect
                                                                                                                    the
                                                                                                                    processing
                                                                                                                    of
                                                                                                                    your
                                                                                                                    personal
                                                                                                                    information
                                                                                                                    conducted
                                                                                                                    in
                                                                                                                    reliance
                                                                                                                    on
                                                                                                                    lawful
                                                                                                                    processing
                                                                                                                    grounds
                                                                                                                    other
                                                                                                                    than
                                                                                                                    consent.</span></span>&nbsp;
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                data-custom-class="body_text"><span
                                                                                                                    style="font-size: 15px;">If
                                                                                                                    you
                                                                                                                    have
                                                                                                                    questions
                                                                                                                    or
                                                                                                                    comments
                                                                                                                    about
                                                                                                                    your
                                                                                                                    privacy
                                                                                                                    rights,
                                                                                                                    you
                                                                                                                    may
                                                                                                                    email
                                                                                                                    us
                                                                                                                    at
                                                                                                                    jpatrick&#64;perlasinsurance.com.</span></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div id="DNT"
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                style="color: rgb(127, 127, 127);"><span
                                                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                id="control"
                                                                                                                                style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                        data-custom-class="heading_1">8.
                                                                                                                                        CONTROLS
                                                                                                                                        FOR
                                                                                                                                        DO-NOT-TRACK
                                                                                                                                        FEATURES</span></strong></span></span></span></span></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                        data-custom-class="body_text">Most
                                                                                                                        web
                                                                                                                        browsers
                                                                                                                        and
                                                                                                                        some
                                                                                                                        mobile
                                                                                                                        operating
                                                                                                                        systems
                                                                                                                        and
                                                                                                                        mobile
                                                                                                                        applications
                                                                                                                        include
                                                                                                                        a
                                                                                                                        Do-Not-Track
                                                                                                                        ("DNT")
                                                                                                                        feature
                                                                                                                        or
                                                                                                                        setting
                                                                                                                        you
                                                                                                                        can
                                                                                                                        activate
                                                                                                                        to
                                                                                                                        signal
                                                                                                                        your
                                                                                                                        privacy
                                                                                                                        preference
                                                                                                                        not
                                                                                                                        to
                                                                                                                        have
                                                                                                                        data
                                                                                                                        about
                                                                                                                        your
                                                                                                                        online
                                                                                                                        browsing
                                                                                                                        activities
                                                                                                                        monitored
                                                                                                                        and
                                                                                                                        collected.
                                                                                                                        At
                                                                                                                        this
                                                                                                                        stage
                                                                                                                        no
                                                                                                                        uniform
                                                                                                                        technology
                                                                                                                        standard
                                                                                                                        for
                                                                                                                        recognizing
                                                                                                                        and
                                                                                                                        implementing
                                                                                                                        DNT
                                                                                                                        signals
                                                                                                                        has
                                                                                                                        been
                                                                                                                        finalized.
                                                                                                                        As
                                                                                                                        such,
                                                                                                                        we
                                                                                                                        do
                                                                                                                        not
                                                                                                                        currently
                                                                                                                        respond
                                                                                                                        to
                                                                                                                        DNT
                                                                                                                        browser
                                                                                                                        signals
                                                                                                                        or
                                                                                                                        any
                                                                                                                        other
                                                                                                                        mechanism
                                                                                                                        that
                                                                                                                        automatically
                                                                                                                        communicates
                                                                                                                        your
                                                                                                                        choice
                                                                                                                        not
                                                                                                                        to
                                                                                                                        be
                                                                                                                        tracked
                                                                                                                        online.
                                                                                                                        If
                                                                                                                        a
                                                                                                                        standard
                                                                                                                        for
                                                                                                                        online
                                                                                                                        tracking
                                                                                                                        is
                                                                                                                        adopted
                                                                                                                        that
                                                                                                                        we
                                                                                                                        must
                                                                                                                        follow
                                                                                                                        in
                                                                                                                        the
                                                                                                                        future,
                                                                                                                        we
                                                                                                                        will
                                                                                                                        inform
                                                                                                                        you
                                                                                                                        about
                                                                                                                        that
                                                                                                                        practice
                                                                                                                        in
                                                                                                                        a
                                                                                                                        revised
                                                                                                                        version
                                                                                                                        of
                                                                                                                        this
                                                                                                                        privacy
                                                                                                                        notice.</span></span></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div id="uslaws"
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                style="color: rgb(127, 127, 127);"><span
                                                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                id="control"
                                                                                                                                style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                        data-custom-class="heading_1">9.
                                                                                                                                        DO
                                                                                                                                        UNITED
                                                                                                                                        STATES
                                                                                                                                        RESIDENTS
                                                                                                                                        HAVE
                                                                                                                                        SPECIFIC
                                                                                                                                        PRIVACY
                                                                                                                                        RIGHTS?</span></strong></span></span></span></span></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                        data-custom-class="body_text"><strong><em>In
                                                                                                                                Short:&nbsp;</em></strong><em>If
                                                                                                                            you
                                                                                                                            are
                                                                                                                            a
                                                                                                                            resident
                                                                                                                            of
                                                                                                                            California,
                                                                                                                            you
                                                                                                                            are
                                                                                                                            granted
                                                                                                                            specific
                                                                                                                            rights
                                                                                                                            regarding
                                                                                                                            access
                                                                                                                            to
                                                                                                                            your
                                                                                                                            personal
                                                                                                                            information.</em></span></span></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                        data-custom-class="body_text"><strong>What
                                                                                                                            categories
                                                                                                                            of
                                                                                                                            personal
                                                                                                                            information
                                                                                                                            do
                                                                                                                            we
                                                                                                                            collect?</strong></span></span></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                        data-custom-class="body_text">We
                                                                                                                        have
                                                                                                                        collected
                                                                                                                        the
                                                                                                                        following
                                                                                                                        categories
                                                                                                                        of
                                                                                                                        personal
                                                                                                                        information
                                                                                                                        in
                                                                                                                        the
                                                                                                                        past
                                                                                                                        twelve
                                                                                                                        (12)
                                                                                                                        months:</span></span></span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <table
                                                                                                            style="width: 100%;">
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td
                                                                                                                        style="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <span
                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    data-custom-class="body_text"><strong>Category</strong></span></span></span>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="width: 51.4385%; border-top: 1px solid black; border-right: 1px solid black;">
                                                                                                                        <span
                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    data-custom-class="body_text"><strong>Examples</strong></span></span></span>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="width: 14.9084%; border-right: 1px solid black; border-top: 1px solid black; text-align: center;">
                                                                                                                        <span
                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    data-custom-class="body_text"><strong>Collected</strong></span></span></span>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td
                                                                                                                        style="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">A.
                                                                                                                                        Identifiers</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="width: 51.4385%; border-top: 1px solid black; border-right: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">Contact
                                                                                                                                        details,
                                                                                                                                        such
                                                                                                                                        as
                                                                                                                                        real
                                                                                                                                        name,
                                                                                                                                        alias,
                                                                                                                                        postal
                                                                                                                                        address,
                                                                                                                                        telephone
                                                                                                                                        or
                                                                                                                                        mobile
                                                                                                                                        contact
                                                                                                                                        number,
                                                                                                                                        unique
                                                                                                                                        personal
                                                                                                                                        identifier,
                                                                                                                                        online
                                                                                                                                        identifier,
                                                                                                                                        Internet
                                                                                                                                        Protocol
                                                                                                                                        address,
                                                                                                                                        email
                                                                                                                                        address,
                                                                                                                                        and
                                                                                                                                        account
                                                                                                                                        name</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="width: 14.9084%; text-align: center; vertical-align: middle; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">YES</span></span></span>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                        </div>
                                                                                                        <table
                                                                                                            style="width: 100%;">
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td
                                                                                                                        style="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">B.
                                                                                                                                        Personal
                                                                                                                                        information
                                                                                                                                        as
                                                                                                                                        defined
                                                                                                                                        in
                                                                                                                                        the
                                                                                                                                        California
                                                                                                                                        Customer
                                                                                                                                        Records
                                                                                                                                        statute</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">Name,
                                                                                                                                        contact
                                                                                                                                        information,
                                                                                                                                        education,
                                                                                                                                        employment,
                                                                                                                                        employment
                                                                                                                                        history,
                                                                                                                                        and
                                                                                                                                        financial
                                                                                                                                        information</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">YES</span></span></span>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                        </div>
                                                                                                        <table
                                                                                                            style="width: 100%;">
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td
                                                                                                                        style="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">C.
                                                                                                                                        Protected
                                                                                                                                        classification
                                                                                                                                        characteristics
                                                                                                                                        under
                                                                                                                                        state
                                                                                                                                        or
                                                                                                                                        federal
                                                                                                                                        law</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">Gender
                                                                                                                                        and
                                                                                                                                        date
                                                                                                                                        of
                                                                                                                                        birth</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                        <div data-custom-class="body_text"
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">NO</span>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td
                                                                                                                        style="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">D.
                                                                                                                                        Commercial
                                                                                                                                        information</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">Transaction
                                                                                                                                        information,
                                                                                                                                        purchase
                                                                                                                                        history,
                                                                                                                                        financial
                                                                                                                                        details,
                                                                                                                                        and
                                                                                                                                        payment
                                                                                                                                        information</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                        <div data-custom-class="body_text"
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">NO</span>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td
                                                                                                                        style="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">E.
                                                                                                                                        Biometric
                                                                                                                                        information</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">Fingerprints
                                                                                                                                        and
                                                                                                                                        voiceprints</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                        <div data-custom-class="body_text"
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">NO</span>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td
                                                                                                                        style="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">F.
                                                                                                                                        Internet
                                                                                                                                        or
                                                                                                                                        other
                                                                                                                                        similar
                                                                                                                                        network
                                                                                                                                        activity</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">Browsing
                                                                                                                                        history,
                                                                                                                                        search
                                                                                                                                        history,
                                                                                                                                        online
                                                                                                                                        behavior,
                                                                                                                                        interest
                                                                                                                                        data,
                                                                                                                                        and
                                                                                                                                        interactions
                                                                                                                                        with
                                                                                                                                        our
                                                                                                                                        and
                                                                                                                                        other
                                                                                                                                        websites,
                                                                                                                                        applications,
                                                                                                                                        systems,
                                                                                                                                        and
                                                                                                                                        advertisements</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                        <div data-custom-class="body_text"
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">NO</span>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td
                                                                                                                        style="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">G.
                                                                                                                                        Geolocation
                                                                                                                                        data</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">Device
                                                                                                                                        location</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                        <div data-custom-class="body_text"
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">NO</span>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td
                                                                                                                        style="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">H.
                                                                                                                                        Audio,
                                                                                                                                        electronic,
                                                                                                                                        visual,
                                                                                                                                        thermal,
                                                                                                                                        olfactory,
                                                                                                                                        or
                                                                                                                                        similar
                                                                                                                                        information</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">Images
                                                                                                                                        and
                                                                                                                                        audio,
                                                                                                                                        video
                                                                                                                                        or
                                                                                                                                        call
                                                                                                                                        recordings
                                                                                                                                        created
                                                                                                                                        in
                                                                                                                                        connection
                                                                                                                                        with
                                                                                                                                        our
                                                                                                                                        business
                                                                                                                                        activities</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                        <div data-custom-class="body_text"
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">NO</span>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td
                                                                                                                        style="width: 33.8274%; border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">I.
                                                                                                                                        Professional
                                                                                                                                        or
                                                                                                                                        employment-related
                                                                                                                                        information</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="width: 51.4385%; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">Business
                                                                                                                                        contact
                                                                                                                                        details
                                                                                                                                        in
                                                                                                                                        order
                                                                                                                                        to
                                                                                                                                        provide
                                                                                                                                        you
                                                                                                                                        our
                                                                                                                                        Services
                                                                                                                                        at
                                                                                                                                        a
                                                                                                                                        business
                                                                                                                                        level
                                                                                                                                        or
                                                                                                                                        job
                                                                                                                                        title,
                                                                                                                                        work
                                                                                                                                        history,
                                                                                                                                        and
                                                                                                                                        professional
                                                                                                                                        qualifications
                                                                                                                                        if
                                                                                                                                        you
                                                                                                                                        apply
                                                                                                                                        for
                                                                                                                                        a
                                                                                                                                        job
                                                                                                                                        with
                                                                                                                                        us</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="width: 14.9084%; text-align: center; border-right: 1px solid black; border-top: 1px solid black;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                        <div data-custom-class="body_text"
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">YES</span>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td
                                                                                                                        style="border-left: 1px solid black; border-right: 1px solid black; border-top: 1px solid black; width: 33.8274%;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">J.
                                                                                                                                        Education
                                                                                                                                        Information</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="border-right: 1px solid black; border-top: 1px solid black; width: 51.4385%;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">Student
                                                                                                                                        records
                                                                                                                                        and
                                                                                                                                        directory
                                                                                                                                        information</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="text-align: center; border-right: 1px solid black; border-top: 1px solid black; width: 14.9084%;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                        <div data-custom-class="body_text"
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">NO</span>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td
                                                                                                                        style="border-width: 1px; border-color: black; border-style: solid; width: 33.8274%;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">K.
                                                                                                                                        Inferences
                                                                                                                                        drawn
                                                                                                                                        from
                                                                                                                                        collected
                                                                                                                                        personal
                                                                                                                                        information</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="border-bottom: 1px solid black; border-top: 1px solid black; border-right: 1px solid black; width: 51.4385%;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                        data-custom-class="body_text">Inferences
                                                                                                                                        drawn
                                                                                                                                        from
                                                                                                                                        any
                                                                                                                                        of
                                                                                                                                        the
                                                                                                                                        collected
                                                                                                                                        personal
                                                                                                                                        information
                                                                                                                                        listed
                                                                                                                                        above
                                                                                                                                        to
                                                                                                                                        create
                                                                                                                                        a
                                                                                                                                        profile
                                                                                                                                        or
                                                                                                                                        summary
                                                                                                                                        about,
                                                                                                                                        for
                                                                                                                                        example,
                                                                                                                                        an
                                                                                                                                        individual’s
                                                                                                                                        preferences
                                                                                                                                        and
                                                                                                                                        characteristics</span></span></span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="text-align: center; border-right: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black; width: 14.9084%;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                        <div data-custom-class="body_text"
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">NO</span>
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td
                                                                                                                        style="border-left: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black; line-height: 1.5;">
                                                                                                                        <span
                                                                                                                            data-custom-class="body_text">L.
                                                                                                                            Sensitive
                                                                                                                            personal
                                                                                                                            Information</span>
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="border-right: 1px solid black; border-bottom: 1px solid black; line-height: 1.5;">
                                                                                                                    </td>
                                                                                                                    <td
                                                                                                                        style="border-right: 1px solid black; border-bottom: 1px solid black;">
                                                                                                                        <div data-empty="true"
                                                                                                                            style="text-align: center;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                        <div data-custom-class="body_text"
                                                                                                                            data-empty="true"
                                                                                                                            style="text-align: center; line-height: 1.5;">
                                                                                                                            <span
                                                                                                                                data-custom-class="body_text">NO</span>
                                                                                                                        </div>
                                                                                                                        <div data-empty="true"
                                                                                                                            style="text-align: center;">
                                                                                                                            <br>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <br>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <span
                                                                                                                data-custom-class="body_text">We
                                                                                                                will use
                                                                                                                and
                                                                                                                retain
                                                                                                                the
                                                                                                                collected
                                                                                                                personal
                                                                                                                information
                                                                                                                as
                                                                                                                needed
                                                                                                                to
                                                                                                                provide
                                                                                                                the
                                                                                                                Services
                                                                                                                or
                                                                                                                for:</span>
                                                                                                        </div>
                                                                                                        <ul>
                                                                                                            <li
                                                                                                                style="line-height: 1.5;">
                                                                                                                <span
                                                                                                                    data-custom-class="body_text">Category
                                                                                                                    A -
                                                                                                                    As
                                                                                                                    long
                                                                                                                    as
                                                                                                                    the
                                                                                                                    user
                                                                                                                    has
                                                                                                                    an
                                                                                                                    account
                                                                                                                    with
                                                                                                                    us</span>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                        </div>
                                                                                                        <ul>
                                                                                                            <li
                                                                                                                style="line-height: 1.5;">
                                                                                                                <span
                                                                                                                    data-custom-class="body_text">Category
                                                                                                                    B -
                                                                                                                    As
                                                                                                                    long
                                                                                                                    as
                                                                                                                    the
                                                                                                                    user
                                                                                                                    has
                                                                                                                    an
                                                                                                                    account
                                                                                                                    with
                                                                                                                    us</span>
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                        <div
                                                                                                            style="line-height: 1.5;">
                                                                                                            <div
                                                                                                                style="line-height: 1.5;">
                                                                                                                <div
                                                                                                                    style="line-height: 1.5;">
                                                                                                                    <div
                                                                                                                        style="line-height: 1.5;">
                                                                                                                        <div
                                                                                                                            style="line-height: 1.5;">
                                                                                                                            <div
                                                                                                                                style="line-height: 1.5;">
                                                                                                                                <div
                                                                                                                                    style="line-height: 1.5;">
                                                                                                                                </div>
                                                                                                                                <ul>
                                                                                                                                    <li
                                                                                                                                        style="line-height: 1.5;">
                                                                                                                                        <span
                                                                                                                                            data-custom-class="body_text">Category
                                                                                                                                            I
                                                                                                                                            -
                                                                                                                                            As
                                                                                                                                            long
                                                                                                                                            as
                                                                                                                                            the
                                                                                                                                            user
                                                                                                                                            has
                                                                                                                                            an
                                                                                                                                            account
                                                                                                                                            with
                                                                                                                                            us</span>
                                                                                                                                    </li>
                                                                                                                                </ul>
                                                                                                                                <div
                                                                                                                                    style="line-height: 1.5;">
                                                                                                                                    <div
                                                                                                                                        style="line-height: 1.5;">
                                                                                                                                        <div
                                                                                                                                            style="line-height: 1.5;">
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text">We
                                                                                                                                                            may
                                                                                                                                                            also
                                                                                                                                                            collect
                                                                                                                                                            other
                                                                                                                                                            personal
                                                                                                                                                            information
                                                                                                                                                            outside
                                                                                                                                                            of
                                                                                                                                                            these
                                                                                                                                                            categories
                                                                                                                                                            through
                                                                                                                                                            instances
                                                                                                                                                            where
                                                                                                                                                            you
                                                                                                                                                            interact
                                                                                                                                                            with
                                                                                                                                                            us
                                                                                                                                                            in
                                                                                                                                                            person,
                                                                                                                                                            online,
                                                                                                                                                            or
                                                                                                                                                            by
                                                                                                                                                            phone
                                                                                                                                                            or
                                                                                                                                                            mail
                                                                                                                                                            in
                                                                                                                                                            the
                                                                                                                                                            context
                                                                                                                                                            of:</span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <ul>
                                                                                                                                                <li
                                                                                                                                                    style="line-height: 1.5;">
                                                                                                                                                    <span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                data-custom-class="body_text">Receiving
                                                                                                                                                                help
                                                                                                                                                                through
                                                                                                                                                                our
                                                                                                                                                                customer
                                                                                                                                                                support
                                                                                                                                                                channels;</span></span></span>
                                                                                                                                                </li>
                                                                                                                                            </ul>
                                                                                                                                            <ul>
                                                                                                                                                <li
                                                                                                                                                    style="line-height: 1.5;">
                                                                                                                                                    <span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                data-custom-class="body_text">Participation
                                                                                                                                                                in
                                                                                                                                                                customer
                                                                                                                                                                surveys
                                                                                                                                                                or
                                                                                                                                                                contests;
                                                                                                                                                                and</span></span></span>
                                                                                                                                                </li>
                                                                                                                                            </ul>
                                                                                                                                            <ul>
                                                                                                                                                <li
                                                                                                                                                    style="line-height: 1.5;">
                                                                                                                                                    <span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                data-custom-class="body_text">Facilitation
                                                                                                                                                                in
                                                                                                                                                                the
                                                                                                                                                                delivery
                                                                                                                                                                of
                                                                                                                                                                our
                                                                                                                                                                Services
                                                                                                                                                                and
                                                                                                                                                                to
                                                                                                                                                                respond
                                                                                                                                                                to
                                                                                                                                                                your
                                                                                                                                                                inquiries.</span></span></span>
                                                                                                                                                </li>
                                                                                                                                            </ul>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text"><strong>How
                                                                                                                                                                do
                                                                                                                                                                we
                                                                                                                                                                use
                                                                                                                                                                and
                                                                                                                                                                share
                                                                                                                                                                your
                                                                                                                                                                personal
                                                                                                                                                                information?</strong></span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    data-custom-class="body_text"
                                                                                                                                                    style="font-size: 15px;">Learn
                                                                                                                                                    about
                                                                                                                                                    how
                                                                                                                                                    we
                                                                                                                                                    use
                                                                                                                                                    your
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    in
                                                                                                                                                    the
                                                                                                                                                    section,
                                                                                                                                                    "</span><a
                                                                                                                                                    (click)="scrollTo('infouse')"><span
                                                                                                                                                        data-custom-class="link"
                                                                                                                                                        style="font-size: 15px;">HOW
                                                                                                                                                        DO
                                                                                                                                                        WE
                                                                                                                                                        PROCESS
                                                                                                                                                        YOUR
                                                                                                                                                        INFORMATION?</span></a><span
                                                                                                                                                    data-custom-class="body_text"
                                                                                                                                                    style="font-size: 15px;">"</span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text"><strong>Will
                                                                                                                                                                your
                                                                                                                                                                information
                                                                                                                                                                be
                                                                                                                                                                shared
                                                                                                                                                                with
                                                                                                                                                                anyone
                                                                                                                                                                else?</strong></span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text">We
                                                                                                                                                            may
                                                                                                                                                            disclose
                                                                                                                                                            your
                                                                                                                                                            personal
                                                                                                                                                            information
                                                                                                                                                            with
                                                                                                                                                            our
                                                                                                                                                            service
                                                                                                                                                            providers
                                                                                                                                                            pursuant
                                                                                                                                                            to
                                                                                                                                                            a
                                                                                                                                                            written
                                                                                                                                                            contract
                                                                                                                                                            between
                                                                                                                                                            us
                                                                                                                                                            and
                                                                                                                                                            each
                                                                                                                                                            service
                                                                                                                                                            provider.
                                                                                                                                                            Learn
                                                                                                                                                            more
                                                                                                                                                            about
                                                                                                                                                            how
                                                                                                                                                            we
                                                                                                                                                            disclose
                                                                                                                                                            personal
                                                                                                                                                            information
                                                                                                                                                            to
                                                                                                                                                            in
                                                                                                                                                            the
                                                                                                                                                            section,
                                                                                                                                                            "</span></span></span><a
                                                                                                                                                            (click)="scrollTo('whoshare')"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                data-custom-class="link">WHEN
                                                                                                                                                                AND
                                                                                                                                                                WITH
                                                                                                                                                                WHOM
                                                                                                                                                                DO
                                                                                                                                                                WE
                                                                                                                                                                SHARE
                                                                                                                                                                YOUR
                                                                                                                                                                PERSONAL
                                                                                                                                                                INFORMATION?</span></span></span></a><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text">"</span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text">We
                                                                                                                                                            may
                                                                                                                                                            use
                                                                                                                                                            your
                                                                                                                                                            personal
                                                                                                                                                            information
                                                                                                                                                            for
                                                                                                                                                            our
                                                                                                                                                            own
                                                                                                                                                            business
                                                                                                                                                            purposes,
                                                                                                                                                            such
                                                                                                                                                            as
                                                                                                                                                            for
                                                                                                                                                            undertaking
                                                                                                                                                            internal
                                                                                                                                                            research
                                                                                                                                                            for
                                                                                                                                                            technological
                                                                                                                                                            development
                                                                                                                                                            and
                                                                                                                                                            demonstration.
                                                                                                                                                            This
                                                                                                                                                            is
                                                                                                                                                            not
                                                                                                                                                            considered
                                                                                                                                                            to
                                                                                                                                                            be
                                                                                                                                                            "selling"
                                                                                                                                                            of
                                                                                                                                                            your
                                                                                                                                                            personal
                                                                                                                                                            information.</span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text">We
                                                                                                                                                            have
                                                                                                                                                            not
                                                                                                                                                            disclosed,
                                                                                                                                                            sold,
                                                                                                                                                            or
                                                                                                                                                            shared
                                                                                                                                                            any
                                                                                                                                                            personal
                                                                                                                                                            information
                                                                                                                                                            to
                                                                                                                                                            third
                                                                                                                                                            parties
                                                                                                                                                            for
                                                                                                                                                            a
                                                                                                                                                            business
                                                                                                                                                            or
                                                                                                                                                            commercial
                                                                                                                                                            purpose
                                                                                                                                                            in
                                                                                                                                                            the
                                                                                                                                                            preceding
                                                                                                                                                            twelve
                                                                                                                                                            (12)
                                                                                                                                                            months.
                                                                                                                                                            We<span
                                                                                                                                                                style="color: rgb(89, 89, 89);">&nbsp;</span>will
                                                                                                                                                            not
                                                                                                                                                            sell
                                                                                                                                                            or
                                                                                                                                                            share
                                                                                                                                                            personal
                                                                                                                                                            information
                                                                                                                                                            in
                                                                                                                                                            the
                                                                                                                                                            future
                                                                                                                                                            belonging
                                                                                                                                                            to
                                                                                                                                                            website
                                                                                                                                                            visitors,
                                                                                                                                                            users,
                                                                                                                                                            and
                                                                                                                                                            other
                                                                                                                                                            consumers.</span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <strong><span
                                                                                                                                                        data-custom-class="heading_2"
                                                                                                                                                        style="font-size: 15px;">California
                                                                                                                                                        Residents</span></strong>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    data-custom-class="body_text"
                                                                                                                                                    style="font-size: 15px;">California
                                                                                                                                                    Civil
                                                                                                                                                    Code
                                                                                                                                                    Section
                                                                                                                                                    1798.83,
                                                                                                                                                    also
                                                                                                                                                    known
                                                                                                                                                    as
                                                                                                                                                    the
                                                                                                                                                    "Shine
                                                                                                                                                    The
                                                                                                                                                    Light"
                                                                                                                                                    law
                                                                                                                                                    permits
                                                                                                                                                    our
                                                                                                                                                    users
                                                                                                                                                    who
                                                                                                                                                    are
                                                                                                                                                    California
                                                                                                                                                    residents
                                                                                                                                                    to
                                                                                                                                                    request
                                                                                                                                                    and
                                                                                                                                                    obtain
                                                                                                                                                    from
                                                                                                                                                    us,
                                                                                                                                                    once
                                                                                                                                                    a
                                                                                                                                                    year
                                                                                                                                                    and
                                                                                                                                                    free
                                                                                                                                                    of
                                                                                                                                                    charge,
                                                                                                                                                    information
                                                                                                                                                    about
                                                                                                                                                    categories
                                                                                                                                                    of
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    (if
                                                                                                                                                    any)
                                                                                                                                                    we
                                                                                                                                                    disclosed
                                                                                                                                                    to
                                                                                                                                                    third
                                                                                                                                                    parties
                                                                                                                                                    for
                                                                                                                                                    direct
                                                                                                                                                    marketing
                                                                                                                                                    purposes
                                                                                                                                                    and
                                                                                                                                                    the
                                                                                                                                                    names
                                                                                                                                                    and
                                                                                                                                                    addresses
                                                                                                                                                    of
                                                                                                                                                    all
                                                                                                                                                    third
                                                                                                                                                    parties
                                                                                                                                                    with
                                                                                                                                                    which
                                                                                                                                                    we
                                                                                                                                                    shared
                                                                                                                                                    personal
                                                                                                                                                    information
                                                                                                                                                    in
                                                                                                                                                    the
                                                                                                                                                    immediately
                                                                                                                                                    preceding
                                                                                                                                                    calendar
                                                                                                                                                    year.
                                                                                                                                                    If
                                                                                                                                                    you
                                                                                                                                                    are
                                                                                                                                                    a
                                                                                                                                                    California
                                                                                                                                                    resident
                                                                                                                                                    and
                                                                                                                                                    would
                                                                                                                                                    like
                                                                                                                                                    to
                                                                                                                                                    make
                                                                                                                                                    such
                                                                                                                                                    a
                                                                                                                                                    request,
                                                                                                                                                    please
                                                                                                                                                    submit
                                                                                                                                                    your
                                                                                                                                                    request
                                                                                                                                                    in
                                                                                                                                                    writing
                                                                                                                                                    to
                                                                                                                                                    us
                                                                                                                                                    using
                                                                                                                                                    the
                                                                                                                                                    contact
                                                                                                                                                    information
                                                                                                                                                    provided
                                                                                                                                                    below.</span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        data-custom-class="body_text"><br></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    data-custom-class="body_text"
                                                                                                                                                    style="font-size: 15px;">If
                                                                                                                                                    you
                                                                                                                                                    are
                                                                                                                                                    under
                                                                                                                                                    18
                                                                                                                                                    years
                                                                                                                                                    of
                                                                                                                                                    age,
                                                                                                                                                    reside
                                                                                                                                                    in
                                                                                                                                                    California,
                                                                                                                                                    and
                                                                                                                                                    have
                                                                                                                                                    a
                                                                                                                                                    registered
                                                                                                                                                    account
                                                                                                                                                    with
                                                                                                                                                    the
                                                                                                                                                    Services,
                                                                                                                                                    you
                                                                                                                                                    have
                                                                                                                                                    the
                                                                                                                                                    right
                                                                                                                                                    to
                                                                                                                                                    request
                                                                                                                                                    removal
                                                                                                                                                    of
                                                                                                                                                    unwanted
                                                                                                                                                    data
                                                                                                                                                    that
                                                                                                                                                    you
                                                                                                                                                    publicly
                                                                                                                                                    post
                                                                                                                                                    on
                                                                                                                                                    the
                                                                                                                                                    Services.
                                                                                                                                                    To
                                                                                                                                                    request
                                                                                                                                                    removal
                                                                                                                                                    of
                                                                                                                                                    such
                                                                                                                                                    data,
                                                                                                                                                    please
                                                                                                                                                    contact
                                                                                                                                                    us
                                                                                                                                                    using
                                                                                                                                                    the
                                                                                                                                                    contact
                                                                                                                                                    information
                                                                                                                                                    provided
                                                                                                                                                    below
                                                                                                                                                    and
                                                                                                                                                    include
                                                                                                                                                    the
                                                                                                                                                    email
                                                                                                                                                    address
                                                                                                                                                    associated
                                                                                                                                                    with
                                                                                                                                                    your
                                                                                                                                                    account
                                                                                                                                                    and
                                                                                                                                                    a
                                                                                                                                                    statement
                                                                                                                                                    that
                                                                                                                                                    you
                                                                                                                                                    reside
                                                                                                                                                    in
                                                                                                                                                    California.
                                                                                                                                                    We
                                                                                                                                                    will
                                                                                                                                                    make
                                                                                                                                                    sure
                                                                                                                                                    the
                                                                                                                                                    data
                                                                                                                                                    is
                                                                                                                                                    not
                                                                                                                                                    publicly
                                                                                                                                                    displayed
                                                                                                                                                    on
                                                                                                                                                    the
                                                                                                                                                    Services,
                                                                                                                                                    but
                                                                                                                                                    please
                                                                                                                                                    be
                                                                                                                                                    aware
                                                                                                                                                    that
                                                                                                                                                    the
                                                                                                                                                    data
                                                                                                                                                    may
                                                                                                                                                    not
                                                                                                                                                    be
                                                                                                                                                    completely
                                                                                                                                                    or
                                                                                                                                                    comprehensively
                                                                                                                                                    removed
                                                                                                                                                    from
                                                                                                                                                    all
                                                                                                                                                    our
                                                                                                                                                    systems
                                                                                                                                                    (e.g.,
                                                                                                                                                    backups,
                                                                                                                                                    etc.).</span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"><br></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        data-custom-class="body_text"><strong><u>CCPA
                                                                                                                                                                Privacy
                                                                                                                                                                Notice</u></strong></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        data-custom-class="body_text"><br></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        data-custom-class="body_text">This
                                                                                                                                                        section
                                                                                                                                                        applies
                                                                                                                                                        only
                                                                                                                                                        to
                                                                                                                                                        California
                                                                                                                                                        residents.
                                                                                                                                                        Under
                                                                                                                                                        the
                                                                                                                                                        California
                                                                                                                                                        Consumer
                                                                                                                                                        Privacy
                                                                                                                                                        Act
                                                                                                                                                        (CCPA),
                                                                                                                                                        you
                                                                                                                                                        have
                                                                                                                                                        the
                                                                                                                                                        rights
                                                                                                                                                        listed
                                                                                                                                                        below.</span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        data-custom-class="body_text"><br></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        data-custom-class="body_text">The
                                                                                                                                                        California
                                                                                                                                                        Code
                                                                                                                                                        of
                                                                                                                                                        Regulations
                                                                                                                                                        defines
                                                                                                                                                        a
                                                                                                                                                        "residents"
                                                                                                                                                        as:</span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        data-custom-class="body_text"><br></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="margin-left: 20px; line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        data-custom-class="body_text">(1)
                                                                                                                                                        every
                                                                                                                                                        individual
                                                                                                                                                        who
                                                                                                                                                        is
                                                                                                                                                        in
                                                                                                                                                        the
                                                                                                                                                        State
                                                                                                                                                        of
                                                                                                                                                        California
                                                                                                                                                        for
                                                                                                                                                        other
                                                                                                                                                        than
                                                                                                                                                        a
                                                                                                                                                        temporary
                                                                                                                                                        or
                                                                                                                                                        transitory
                                                                                                                                                        purpose
                                                                                                                                                        and</span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="margin-left: 20px; line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        data-custom-class="body_text">(2)
                                                                                                                                                        every
                                                                                                                                                        individual
                                                                                                                                                        who
                                                                                                                                                        is
                                                                                                                                                        domiciled
                                                                                                                                                        in
                                                                                                                                                        the
                                                                                                                                                        State
                                                                                                                                                        of
                                                                                                                                                        California
                                                                                                                                                        who
                                                                                                                                                        is
                                                                                                                                                        outside
                                                                                                                                                        the
                                                                                                                                                        State
                                                                                                                                                        of
                                                                                                                                                        California
                                                                                                                                                        for
                                                                                                                                                        a
                                                                                                                                                        temporary
                                                                                                                                                        or
                                                                                                                                                        transitory
                                                                                                                                                        purpose</span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        data-custom-class="body_text"><br></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        data-custom-class="body_text">All
                                                                                                                                                        other
                                                                                                                                                        individuals
                                                                                                                                                        are
                                                                                                                                                        defined
                                                                                                                                                        as
                                                                                                                                                        "non-residents."</span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        data-custom-class="body_text"><br></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        data-custom-class="body_text">If
                                                                                                                                                        this
                                                                                                                                                        definition
                                                                                                                                                        of
                                                                                                                                                        "resident"
                                                                                                                                                        applies
                                                                                                                                                        to
                                                                                                                                                        you,
                                                                                                                                                        we
                                                                                                                                                        must
                                                                                                                                                        adhere
                                                                                                                                                        to
                                                                                                                                                        certain
                                                                                                                                                        rights
                                                                                                                                                        and
                                                                                                                                                        obligations
                                                                                                                                                        regarding
                                                                                                                                                        your
                                                                                                                                                        personal
                                                                                                                                                        information.</span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text"><strong>Your
                                                                                                                                                                rights
                                                                                                                                                                with
                                                                                                                                                                respect
                                                                                                                                                                to
                                                                                                                                                                your
                                                                                                                                                                personal
                                                                                                                                                                data</strong></span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text"><u>Right
                                                                                                                                                                to
                                                                                                                                                                request
                                                                                                                                                                deletion
                                                                                                                                                                of
                                                                                                                                                                the
                                                                                                                                                                data
                                                                                                                                                                —
                                                                                                                                                                Request
                                                                                                                                                                to
                                                                                                                                                                delete</u></span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text">You
                                                                                                                                                            can
                                                                                                                                                            ask
                                                                                                                                                            for
                                                                                                                                                            the
                                                                                                                                                            deletion
                                                                                                                                                            of
                                                                                                                                                            your
                                                                                                                                                            personal
                                                                                                                                                            information.
                                                                                                                                                            If
                                                                                                                                                            you
                                                                                                                                                            ask
                                                                                                                                                            us
                                                                                                                                                            to
                                                                                                                                                            delete
                                                                                                                                                            your
                                                                                                                                                            personal
                                                                                                                                                            information,
                                                                                                                                                            we
                                                                                                                                                            will
                                                                                                                                                            respect
                                                                                                                                                            your
                                                                                                                                                            request
                                                                                                                                                            and
                                                                                                                                                            delete
                                                                                                                                                            your
                                                                                                                                                            personal
                                                                                                                                                            information,
                                                                                                                                                            subject
                                                                                                                                                            to
                                                                                                                                                            certain
                                                                                                                                                            exceptions
                                                                                                                                                            provided
                                                                                                                                                            by
                                                                                                                                                            law,
                                                                                                                                                            such
                                                                                                                                                            as
                                                                                                                                                            (but
                                                                                                                                                            not
                                                                                                                                                            limited
                                                                                                                                                            to)
                                                                                                                                                            the
                                                                                                                                                            exercise
                                                                                                                                                            by
                                                                                                                                                            another
                                                                                                                                                            consumer
                                                                                                                                                            of
                                                                                                                                                            his
                                                                                                                                                            or
                                                                                                                                                            her
                                                                                                                                                            right
                                                                                                                                                            to
                                                                                                                                                            free
                                                                                                                                                            speech,
                                                                                                                                                            our
                                                                                                                                                            compliance
                                                                                                                                                            requirements
                                                                                                                                                            resulting
                                                                                                                                                            from
                                                                                                                                                            a
                                                                                                                                                            legal
                                                                                                                                                            obligation,
                                                                                                                                                            or
                                                                                                                                                            any
                                                                                                                                                            processing
                                                                                                                                                            that
                                                                                                                                                            may
                                                                                                                                                            be
                                                                                                                                                            required
                                                                                                                                                            to
                                                                                                                                                            protect
                                                                                                                                                            against
                                                                                                                                                            illegal
                                                                                                                                                            activities.</span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text"><u>Right
                                                                                                                                                                to
                                                                                                                                                                be
                                                                                                                                                                informed
                                                                                                                                                                —
                                                                                                                                                                Request
                                                                                                                                                                to
                                                                                                                                                                know</u></span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text">Depending
                                                                                                                                                            on
                                                                                                                                                            the
                                                                                                                                                            circumstances,
                                                                                                                                                            you
                                                                                                                                                            have
                                                                                                                                                            a
                                                                                                                                                            right
                                                                                                                                                            to
                                                                                                                                                            know:</span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <ul>
                                                                                                                                                <li
                                                                                                                                                    style="line-height: 1.5;">
                                                                                                                                                    <span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                data-custom-class="body_text">whether
                                                                                                                                                                we
                                                                                                                                                                collect
                                                                                                                                                                and
                                                                                                                                                                use
                                                                                                                                                                your
                                                                                                                                                                personal
                                                                                                                                                                information;</span></span></span>
                                                                                                                                                </li>
                                                                                                                                            </ul>
                                                                                                                                            <ul>
                                                                                                                                                <li
                                                                                                                                                    style="line-height: 1.5;">
                                                                                                                                                    <span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                data-custom-class="body_text">the
                                                                                                                                                                categories
                                                                                                                                                                of
                                                                                                                                                                personal
                                                                                                                                                                information
                                                                                                                                                                that
                                                                                                                                                                we
                                                                                                                                                                collect;</span></span></span>
                                                                                                                                                </li>
                                                                                                                                            </ul>
                                                                                                                                            <ul>
                                                                                                                                                <li
                                                                                                                                                    style="line-height: 1.5;">
                                                                                                                                                    <span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                data-custom-class="body_text">the
                                                                                                                                                                purposes
                                                                                                                                                                for
                                                                                                                                                                which
                                                                                                                                                                the
                                                                                                                                                                collected
                                                                                                                                                                personal
                                                                                                                                                                information
                                                                                                                                                                is
                                                                                                                                                                used;</span></span></span>
                                                                                                                                                </li>
                                                                                                                                            </ul>
                                                                                                                                            <ul>
                                                                                                                                                <li
                                                                                                                                                    style="line-height: 1.5;">
                                                                                                                                                    <span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                data-custom-class="body_text">whether
                                                                                                                                                                we
                                                                                                                                                                sell
                                                                                                                                                                or
                                                                                                                                                                share
                                                                                                                                                                personal
                                                                                                                                                                information
                                                                                                                                                                to
                                                                                                                                                                third
                                                                                                                                                                parties;</span></span></span>
                                                                                                                                                </li>
                                                                                                                                            </ul>
                                                                                                                                            <ul>
                                                                                                                                                <li
                                                                                                                                                    style="line-height: 1.5;">
                                                                                                                                                    <span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                data-custom-class="body_text">the
                                                                                                                                                                categories
                                                                                                                                                                of
                                                                                                                                                                personal
                                                                                                                                                                information
                                                                                                                                                                that
                                                                                                                                                                we
                                                                                                                                                                sold,
                                                                                                                                                                shared,
                                                                                                                                                                or
                                                                                                                                                                disclosed
                                                                                                                                                                for
                                                                                                                                                                a
                                                                                                                                                                business
                                                                                                                                                                purpose;</span></span></span>
                                                                                                                                                </li>
                                                                                                                                            </ul>
                                                                                                                                            <ul>
                                                                                                                                                <li
                                                                                                                                                    style="line-height: 1.5;">
                                                                                                                                                    <span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                data-custom-class="body_text">the
                                                                                                                                                                categories
                                                                                                                                                                of
                                                                                                                                                                third
                                                                                                                                                                parties
                                                                                                                                                                to
                                                                                                                                                                whom
                                                                                                                                                                the
                                                                                                                                                                personal
                                                                                                                                                                information
                                                                                                                                                                was
                                                                                                                                                                sold,
                                                                                                                                                                shared,
                                                                                                                                                                or
                                                                                                                                                                disclosed
                                                                                                                                                                for
                                                                                                                                                                a
                                                                                                                                                                business
                                                                                                                                                                purpose;</span></span></span>
                                                                                                                                                </li>
                                                                                                                                            </ul>
                                                                                                                                            <ul>
                                                                                                                                                <li
                                                                                                                                                    style="line-height: 1.5;">
                                                                                                                                                    <span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                data-custom-class="body_text">the
                                                                                                                                                                business
                                                                                                                                                                or
                                                                                                                                                                commercial
                                                                                                                                                                purpose
                                                                                                                                                                for
                                                                                                                                                                collecting,
                                                                                                                                                                selling,
                                                                                                                                                                or
                                                                                                                                                                sharing
                                                                                                                                                                personal
                                                                                                                                                                information;
                                                                                                                                                                and</span></span></span>
                                                                                                                                                </li>
                                                                                                                                            </ul>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                            </div>
                                                                                                                                            <ul>
                                                                                                                                                <li
                                                                                                                                                    style="line-height: 1.5;">
                                                                                                                                                    <span
                                                                                                                                                        data-custom-class="body_text">the
                                                                                                                                                        specific
                                                                                                                                                        pieces
                                                                                                                                                        of
                                                                                                                                                        personal
                                                                                                                                                        information
                                                                                                                                                        we
                                                                                                                                                        collected
                                                                                                                                                        about
                                                                                                                                                        you.</span>
                                                                                                                                                </li>
                                                                                                                                            </ul>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text">In
                                                                                                                                                            accordance
                                                                                                                                                            with
                                                                                                                                                            applicable
                                                                                                                                                            law,
                                                                                                                                                            we
                                                                                                                                                            are
                                                                                                                                                            not
                                                                                                                                                            obligated
                                                                                                                                                            to
                                                                                                                                                            provide
                                                                                                                                                            or
                                                                                                                                                            delete
                                                                                                                                                            consumer
                                                                                                                                                            information
                                                                                                                                                            that
                                                                                                                                                            is
                                                                                                                                                            de-identified
                                                                                                                                                            in
                                                                                                                                                            response
                                                                                                                                                            to
                                                                                                                                                            a
                                                                                                                                                            consumer
                                                                                                                                                            request
                                                                                                                                                            or
                                                                                                                                                            to
                                                                                                                                                            re-identify
                                                                                                                                                            individual
                                                                                                                                                            data
                                                                                                                                                            to
                                                                                                                                                            verify
                                                                                                                                                            a
                                                                                                                                                            consumer
                                                                                                                                                            request.</span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text"><u>Right
                                                                                                                                                                to
                                                                                                                                                                Non-Discrimination
                                                                                                                                                                for
                                                                                                                                                                the
                                                                                                                                                                Exercise
                                                                                                                                                                of
                                                                                                                                                                a
                                                                                                                                                                Consumer’s
                                                                                                                                                                Privacy
                                                                                                                                                                Rights</u></span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text">We
                                                                                                                                                            will
                                                                                                                                                            not
                                                                                                                                                            discriminate
                                                                                                                                                            against
                                                                                                                                                            you
                                                                                                                                                            if
                                                                                                                                                            you
                                                                                                                                                            exercise
                                                                                                                                                            your
                                                                                                                                                            privacy
                                                                                                                                                            rights.</span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <u><span
                                                                                                                                                        data-custom-class="body_text">Right
                                                                                                                                                        to
                                                                                                                                                        Limit
                                                                                                                                                        Use
                                                                                                                                                        and
                                                                                                                                                        Disclosure
                                                                                                                                                        of
                                                                                                                                                        Sensitive
                                                                                                                                                        Personal
                                                                                                                                                        Information</span></u>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    data-custom-class="body_text">We
                                                                                                                                                    do
                                                                                                                                                    not
                                                                                                                                                    process
                                                                                                                                                    consumer's
                                                                                                                                                    sensitive
                                                                                                                                                    personal
                                                                                                                                                    information.</span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text"><u>Verification
                                                                                                                                                                process</u></span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text">Upon
                                                                                                                                                            receiving
                                                                                                                                                            your
                                                                                                                                                            request,
                                                                                                                                                            we
                                                                                                                                                            will
                                                                                                                                                            need
                                                                                                                                                            to
                                                                                                                                                            verify
                                                                                                                                                            your
                                                                                                                                                            identity
                                                                                                                                                            to
                                                                                                                                                            determine
                                                                                                                                                            you
                                                                                                                                                            are
                                                                                                                                                            the
                                                                                                                                                            same
                                                                                                                                                            person
                                                                                                                                                            about
                                                                                                                                                            whom
                                                                                                                                                            we
                                                                                                                                                            have
                                                                                                                                                            the
                                                                                                                                                            information
                                                                                                                                                            in
                                                                                                                                                            our
                                                                                                                                                            system.
                                                                                                                                                            These
                                                                                                                                                            verification
                                                                                                                                                            efforts
                                                                                                                                                            require
                                                                                                                                                            us
                                                                                                                                                            to
                                                                                                                                                            ask
                                                                                                                                                            you
                                                                                                                                                            to
                                                                                                                                                            provide
                                                                                                                                                            information
                                                                                                                                                            so
                                                                                                                                                            that
                                                                                                                                                            we
                                                                                                                                                            can
                                                                                                                                                            match
                                                                                                                                                            it
                                                                                                                                                            with
                                                                                                                                                            information
                                                                                                                                                            you
                                                                                                                                                            have
                                                                                                                                                            previously
                                                                                                                                                            provided
                                                                                                                                                            us.
                                                                                                                                                            For
                                                                                                                                                            instance,
                                                                                                                                                            depending
                                                                                                                                                            on
                                                                                                                                                            the
                                                                                                                                                            type
                                                                                                                                                            of
                                                                                                                                                            request
                                                                                                                                                            you
                                                                                                                                                            submit,
                                                                                                                                                            we
                                                                                                                                                            may
                                                                                                                                                            ask
                                                                                                                                                            you
                                                                                                                                                            to
                                                                                                                                                            provide
                                                                                                                                                            certain
                                                                                                                                                            information
                                                                                                                                                            so
                                                                                                                                                            that
                                                                                                                                                            we
                                                                                                                                                            can
                                                                                                                                                            match
                                                                                                                                                            the
                                                                                                                                                            information
                                                                                                                                                            you
                                                                                                                                                            provide
                                                                                                                                                            with
                                                                                                                                                            the
                                                                                                                                                            information
                                                                                                                                                            we
                                                                                                                                                            already
                                                                                                                                                            have
                                                                                                                                                            on
                                                                                                                                                            file,
                                                                                                                                                            or
                                                                                                                                                            we
                                                                                                                                                            may
                                                                                                                                                            contact
                                                                                                                                                            you
                                                                                                                                                            through
                                                                                                                                                            a
                                                                                                                                                            communication
                                                                                                                                                            method
                                                                                                                                                            (e.g.,
                                                                                                                                                            phone
                                                                                                                                                            or
                                                                                                                                                            email)
                                                                                                                                                            that
                                                                                                                                                            you
                                                                                                                                                            have
                                                                                                                                                            previously
                                                                                                                                                            provided
                                                                                                                                                            to
                                                                                                                                                            us.
                                                                                                                                                            We
                                                                                                                                                            may
                                                                                                                                                            also
                                                                                                                                                            use
                                                                                                                                                            other
                                                                                                                                                            verification
                                                                                                                                                            methods
                                                                                                                                                            as
                                                                                                                                                            the
                                                                                                                                                            circumstances
                                                                                                                                                            dictate.</span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text">We
                                                                                                                                                            will
                                                                                                                                                            only
                                                                                                                                                            use
                                                                                                                                                            personal
                                                                                                                                                            information
                                                                                                                                                            provided
                                                                                                                                                            in
                                                                                                                                                            your
                                                                                                                                                            request
                                                                                                                                                            to
                                                                                                                                                            verify
                                                                                                                                                            your
                                                                                                                                                            identity
                                                                                                                                                            or
                                                                                                                                                            authority
                                                                                                                                                            to
                                                                                                                                                            make
                                                                                                                                                            the
                                                                                                                                                            request.
                                                                                                                                                            To
                                                                                                                                                            the
                                                                                                                                                            extent
                                                                                                                                                            possible,
                                                                                                                                                            we
                                                                                                                                                            will
                                                                                                                                                            avoid
                                                                                                                                                            requesting
                                                                                                                                                            additional
                                                                                                                                                            information
                                                                                                                                                            from
                                                                                                                                                            you
                                                                                                                                                            for
                                                                                                                                                            the
                                                                                                                                                            purposes
                                                                                                                                                            of
                                                                                                                                                            verification.
                                                                                                                                                            However,
                                                                                                                                                            if
                                                                                                                                                            we
                                                                                                                                                            cannot
                                                                                                                                                            verify
                                                                                                                                                            your
                                                                                                                                                            identity
                                                                                                                                                            from
                                                                                                                                                            the
                                                                                                                                                            information
                                                                                                                                                            already
                                                                                                                                                            maintained
                                                                                                                                                            by
                                                                                                                                                            us,
                                                                                                                                                            we
                                                                                                                                                            may
                                                                                                                                                            request
                                                                                                                                                            that
                                                                                                                                                            you
                                                                                                                                                            provide
                                                                                                                                                            additional
                                                                                                                                                            information
                                                                                                                                                            for
                                                                                                                                                            the
                                                                                                                                                            purposes
                                                                                                                                                            of
                                                                                                                                                            verifying
                                                                                                                                                            your
                                                                                                                                                            identity
                                                                                                                                                            and
                                                                                                                                                            for
                                                                                                                                                            security
                                                                                                                                                            or
                                                                                                                                                            fraud-prevention
                                                                                                                                                            purposes.
                                                                                                                                                            We
                                                                                                                                                            will
                                                                                                                                                            delete
                                                                                                                                                            such
                                                                                                                                                            additionally
                                                                                                                                                            provided
                                                                                                                                                            information
                                                                                                                                                            as
                                                                                                                                                            soon
                                                                                                                                                            as
                                                                                                                                                            we
                                                                                                                                                            finish
                                                                                                                                                            verifying
                                                                                                                                                            you.</span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text"><u>Other
                                                                                                                                                                privacy
                                                                                                                                                                rights</u></span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <ul>
                                                                                                                                                <li
                                                                                                                                                    style="line-height: 1.5;">
                                                                                                                                                    <span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                data-custom-class="body_text">You
                                                                                                                                                                may
                                                                                                                                                                object
                                                                                                                                                                to
                                                                                                                                                                the
                                                                                                                                                                processing
                                                                                                                                                                of
                                                                                                                                                                your
                                                                                                                                                                personal
                                                                                                                                                                information.</span></span></span>
                                                                                                                                                </li>
                                                                                                                                            </ul>
                                                                                                                                            <ul>
                                                                                                                                                <li
                                                                                                                                                    style="line-height: 1.5;">
                                                                                                                                                    <span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                data-custom-class="body_text">You
                                                                                                                                                                may
                                                                                                                                                                request
                                                                                                                                                                correction
                                                                                                                                                                of
                                                                                                                                                                your
                                                                                                                                                                personal
                                                                                                                                                                data
                                                                                                                                                                if
                                                                                                                                                                it
                                                                                                                                                                is
                                                                                                                                                                incorrect
                                                                                                                                                                or
                                                                                                                                                                no
                                                                                                                                                                longer
                                                                                                                                                                relevant,
                                                                                                                                                                or
                                                                                                                                                                ask
                                                                                                                                                                to
                                                                                                                                                                restrict
                                                                                                                                                                the
                                                                                                                                                                processing
                                                                                                                                                                of
                                                                                                                                                                the
                                                                                                                                                                information.</span></span></span>
                                                                                                                                                </li>
                                                                                                                                            </ul>
                                                                                                                                            <ul>
                                                                                                                                                <li
                                                                                                                                                    style="line-height: 1.5;">
                                                                                                                                                    <span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                data-custom-class="body_text">You
                                                                                                                                                                can
                                                                                                                                                                designate
                                                                                                                                                                an
                                                                                                                                                                authorized
                                                                                                                                                                agent
                                                                                                                                                                to
                                                                                                                                                                make
                                                                                                                                                                a
                                                                                                                                                                request
                                                                                                                                                                under
                                                                                                                                                                the
                                                                                                                                                                CCPA
                                                                                                                                                                on
                                                                                                                                                                your
                                                                                                                                                                behalf.
                                                                                                                                                                We
                                                                                                                                                                may
                                                                                                                                                                deny
                                                                                                                                                                a
                                                                                                                                                                request
                                                                                                                                                                from
                                                                                                                                                                an
                                                                                                                                                                authorized
                                                                                                                                                                agent
                                                                                                                                                                that
                                                                                                                                                                does
                                                                                                                                                                not
                                                                                                                                                                submit
                                                                                                                                                                proof
                                                                                                                                                                that
                                                                                                                                                                they
                                                                                                                                                                have
                                                                                                                                                                been
                                                                                                                                                                validly
                                                                                                                                                                authorized
                                                                                                                                                                to
                                                                                                                                                                act
                                                                                                                                                                on
                                                                                                                                                                your
                                                                                                                                                                behalf
                                                                                                                                                                in
                                                                                                                                                                accordance
                                                                                                                                                                with
                                                                                                                                                                the
                                                                                                                                                                CCPA.</span></span></span>
                                                                                                                                                </li>
                                                                                                                                            </ul>
                                                                                                                                            <ul>
                                                                                                                                                <li
                                                                                                                                                    style="line-height: 1.5;">
                                                                                                                                                    <span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                data-custom-class="body_text">You
                                                                                                                                                                may
                                                                                                                                                                request
                                                                                                                                                                to
                                                                                                                                                                opt
                                                                                                                                                                out
                                                                                                                                                                from
                                                                                                                                                                future
                                                                                                                                                                selling
                                                                                                                                                                or
                                                                                                                                                                sharing
                                                                                                                                                                of
                                                                                                                                                                your
                                                                                                                                                                personal
                                                                                                                                                                information
                                                                                                                                                                to
                                                                                                                                                                third
                                                                                                                                                                parties.
                                                                                                                                                                Upon
                                                                                                                                                                receiving
                                                                                                                                                                an
                                                                                                                                                                opt-out
                                                                                                                                                                request,
                                                                                                                                                                we
                                                                                                                                                                will
                                                                                                                                                                act
                                                                                                                                                                upon
                                                                                                                                                                the
                                                                                                                                                                request
                                                                                                                                                                as
                                                                                                                                                                soon
                                                                                                                                                                as
                                                                                                                                                                feasibly
                                                                                                                                                                possible,
                                                                                                                                                                but
                                                                                                                                                                no
                                                                                                                                                                later
                                                                                                                                                                than
                                                                                                                                                                fifteen
                                                                                                                                                                (15)
                                                                                                                                                                days
                                                                                                                                                                from
                                                                                                                                                                the
                                                                                                                                                                date
                                                                                                                                                                of
                                                                                                                                                                the
                                                                                                                                                                request
                                                                                                                                                                submission.</span></span></span>
                                                                                                                                                </li>
                                                                                                                                            </ul>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text">To
                                                                                                                                                            exercise
                                                                                                                                                            these
                                                                                                                                                            rights,
                                                                                                                                                            you
                                                                                                                                                            can
                                                                                                                                                            contact
                                                                                                                                                            us
                                                                                                                                                            by
                                                                                                                                                            submitting
                                                                                                                                                            a&nbsp;</span></span></span><a
                                                                                                                                                    href="https://app.termly.io/notify/cde31c17-9fe4-4f4b-a6c1-2d7b14159a68"
                                                                                                                                                    rel="noopener noreferrer"
                                                                                                                                                    target="_blank"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                data-custom-class="link">data
                                                                                                                                                                subject
                                                                                                                                                                access
                                                                                                                                                                request</span></span></span></a><span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text">,</span>
                                                                                                                                                        <span
                                                                                                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                data-custom-class="body_text"><span
                                                                                                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                        data-custom-class="body_text">by
                                                                                                                                                                        email
                                                                                                                                                                        at
                                                                                                                                                                        jpatrick&#64;perlasinsurance.com,
                                                                                                                                                                        by
                                                                                                                                                                        calling
                                                                                                                                                                        toll-free
                                                                                                                                                                        at
                                                                                                                                                                        1-877-737-5271,</span></span></span></span></span></span>
                                                                                                                                                <span
                                                                                                                                                    data-custom-class="body_text">or
                                                                                                                                                    by
                                                                                                                                                    referring
                                                                                                                                                    to
                                                                                                                                                    the
                                                                                                                                                    contact
                                                                                                                                                    details
                                                                                                                                                    at
                                                                                                                                                    the
                                                                                                                                                    bottom
                                                                                                                                                    of
                                                                                                                                                    this
                                                                                                                                                    document.
                                                                                                                                                    If
                                                                                                                                                    you
                                                                                                                                                    have
                                                                                                                                                    a
                                                                                                                                                    complaint
                                                                                                                                                    about
                                                                                                                                                    how
                                                                                                                                                    we
                                                                                                                                                    handle
                                                                                                                                                    your
                                                                                                                                                    data,
                                                                                                                                                    we
                                                                                                                                                    would
                                                                                                                                                    like
                                                                                                                                                    to
                                                                                                                                                    hear
                                                                                                                                                    from
                                                                                                                                                    you.</span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div id="policyupdates"
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="color: rgb(127, 127, 127);"><span
                                                                                                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                    id="control"
                                                                                                                                                                    style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                            data-custom-class="heading_1">10.
                                                                                                                                                                            DO
                                                                                                                                                                            WE
                                                                                                                                                                            MAKE
                                                                                                                                                                            UPDATES
                                                                                                                                                                            TO
                                                                                                                                                                            THIS
                                                                                                                                                                            NOTICE?</span></strong></span></span></span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text"><em><strong>In
                                                                                                                                                                    Short:&nbsp;</strong>Yes,
                                                                                                                                                                we
                                                                                                                                                                will
                                                                                                                                                                update
                                                                                                                                                                this
                                                                                                                                                                notice
                                                                                                                                                                as
                                                                                                                                                                necessary
                                                                                                                                                                to
                                                                                                                                                                stay
                                                                                                                                                                compliant
                                                                                                                                                                with
                                                                                                                                                                relevant
                                                                                                                                                                laws.</em></span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text">We
                                                                                                                                                            may
                                                                                                                                                            update
                                                                                                                                                            this
                                                                                                                                                            privacy
                                                                                                                                                            notice
                                                                                                                                                            from
                                                                                                                                                            time
                                                                                                                                                            to
                                                                                                                                                            time.
                                                                                                                                                            The
                                                                                                                                                            updated
                                                                                                                                                            version
                                                                                                                                                            will
                                                                                                                                                            be
                                                                                                                                                            indicated
                                                                                                                                                            by
                                                                                                                                                            an
                                                                                                                                                            updated
                                                                                                                                                            "Revised"
                                                                                                                                                            date
                                                                                                                                                            and
                                                                                                                                                            the
                                                                                                                                                            updated
                                                                                                                                                            version
                                                                                                                                                            will
                                                                                                                                                            be
                                                                                                                                                            effective
                                                                                                                                                            as
                                                                                                                                                            soon
                                                                                                                                                            as
                                                                                                                                                            it
                                                                                                                                                            is
                                                                                                                                                            accessible.
                                                                                                                                                            If
                                                                                                                                                            we
                                                                                                                                                            make
                                                                                                                                                            material
                                                                                                                                                            changes
                                                                                                                                                            to
                                                                                                                                                            this
                                                                                                                                                            privacy
                                                                                                                                                            notice,
                                                                                                                                                            we
                                                                                                                                                            may
                                                                                                                                                            notify
                                                                                                                                                            you
                                                                                                                                                            either
                                                                                                                                                            by
                                                                                                                                                            prominently
                                                                                                                                                            posting
                                                                                                                                                            a
                                                                                                                                                            notice
                                                                                                                                                            of
                                                                                                                                                            such
                                                                                                                                                            changes
                                                                                                                                                            or
                                                                                                                                                            by
                                                                                                                                                            directly
                                                                                                                                                            sending
                                                                                                                                                            you
                                                                                                                                                            a
                                                                                                                                                            notification.
                                                                                                                                                            We
                                                                                                                                                            encourage
                                                                                                                                                            you
                                                                                                                                                            to
                                                                                                                                                            review
                                                                                                                                                            this
                                                                                                                                                            privacy
                                                                                                                                                            notice
                                                                                                                                                            frequently
                                                                                                                                                            to
                                                                                                                                                            be
                                                                                                                                                            informed
                                                                                                                                                            of
                                                                                                                                                            how
                                                                                                                                                            we
                                                                                                                                                            are
                                                                                                                                                            protecting
                                                                                                                                                            your
                                                                                                                                                            information.</span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div id="contact"
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="color: rgb(127, 127, 127);"><span
                                                                                                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                    id="control"
                                                                                                                                                                    style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                            data-custom-class="heading_1">11.
                                                                                                                                                                            HOW
                                                                                                                                                                            CAN
                                                                                                                                                                            YOU
                                                                                                                                                                            CONTACT
                                                                                                                                                                            US
                                                                                                                                                                            ABOUT
                                                                                                                                                                            THIS
                                                                                                                                                                            NOTICE?</span></strong></span></span></span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text">If
                                                                                                                                                            you
                                                                                                                                                            have
                                                                                                                                                            questions
                                                                                                                                                            or
                                                                                                                                                            comments
                                                                                                                                                            about
                                                                                                                                                            this
                                                                                                                                                            notice,
                                                                                                                                                            you
                                                                                                                                                            may
                                                                                                                                                            <span
                                                                                                                                                                style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                                    data-custom-class="body_text">email
                                                                                                                                                                    us
                                                                                                                                                                    at
                                                                                                                                                                    jpatrick&#64;perlasinsurance.com
                                                                                                                                                                    or&nbsp;</span></span><span
                                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                        data-custom-class="body_text">contact
                                                                                                                                                                        us
                                                                                                                                                                        by
                                                                                                                                                                        post
                                                                                                                                                                        at:</span></span></span></span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text"><span
                                                                                                                                                                style="font-size: 15px;"><span
                                                                                                                                                                    style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                            data-custom-class="body_text">Perlas
                                                                                                                                                                            Group,
                                                                                                                                                                            Inc.</span></span></span></span></span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        data-custom-class="body_text">107
                                                                                                                                                        E.
                                                                                                                                                        Warm
                                                                                                                                                        Springs
                                                                                                                                                        Rd.</span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px;"><span
                                                                                                                                                        data-custom-class="body_text">Las
                                                                                                                                                        Vegas<span
                                                                                                                                                            style="color: rgb(89, 89, 89);"><span
                                                                                                                                                                style="font-size: 15px;">,
                                                                                                                                                                NV
                                                                                                                                                                89119</span></span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    data-custom-class="body_text"
                                                                                                                                                    style="font-size: 15px;">United
                                                                                                                                                    States</span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div id="request"
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="color: rgb(127, 127, 127);"><span
                                                                                                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                                    id="control"
                                                                                                                                                                    style="color: rgb(0, 0, 0);"><strong><span
                                                                                                                                                                            data-custom-class="heading_1">12.
                                                                                                                                                                            HOW
                                                                                                                                                                            CAN
                                                                                                                                                                            YOU
                                                                                                                                                                            REVIEW,
                                                                                                                                                                            UPDATE,
                                                                                                                                                                            OR
                                                                                                                                                                            DELETE
                                                                                                                                                                            THE
                                                                                                                                                                            DATA
                                                                                                                                                                            WE
                                                                                                                                                                            COLLECT
                                                                                                                                                                            FROM
                                                                                                                                                                            YOU?</span></strong></span></span></span></span></span>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <br>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                style="line-height: 1.5;">
                                                                                                                                                <span
                                                                                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                                                                                            data-custom-class="body_text">Based
                                                                                                                                                            on
                                                                                                                                                            the
                                                                                                                                                            applicable
                                                                                                                                                            laws
                                                                                                                                                            of
                                                                                                                                                            your
                                                                                                                                                            country,
                                                                                                                                                            you
                                                                                                                                                            may
                                                                                                                                                            have
                                                                                                                                                            the
                                                                                                                                                            right
                                                                                                                                                            to
                                                                                                                                                            request
                                                                                                                                                            access
                                                                                                                                                            to
                                                                                                                                                            the
                                                                                                                                                            personal
                                                                                                                                                            information
                                                                                                                                                            we
                                                                                                                                                            collect
                                                                                                                                                            from
                                                                                                                                                            you,
                                                                                                                                                            change
                                                                                                                                                            that
                                                                                                                                                            information,
                                                                                                                                                            or
                                                                                                                                                            delete
                                                                                                                                                            it.
                                                                                                                                                            To
                                                                                                                                                            request
                                                                                                                                                            to
                                                                                                                                                            review,
                                                                                                                                                            update,
                                                                                                                                                            or
                                                                                                                                                            delete
                                                                                                                                                            your
                                                                                                                                                            personal
                                                                                                                                                            information,
                                                                                                                                                            please
                                                                                                                                                            fill
                                                                                                                                                            out
                                                                                                                                                            and
                                                                                                                                                            submit
                                                                                                                                                            a&nbsp;</span><span
                                                                                                                                                            style="color: rgb(48, 48, 241);"><span
                                                                                                                                                                data-custom-class="body_text"><span
                                                                                                                                                                    style="font-size: 15px;"><a
                                                                                                                                                                        data-custom-class="link"
                                                                                                                                                                        href="https://app.termly.io/notify/cde31c17-9fe4-4f4b-a6c1-2d7b14159a68"
                                                                                                                                                                        rel="noopener noreferrer"
                                                                                                                                                                        target="_blank">data
                                                                                                                                                                        subject
                                                                                                                                                                        access
                                                                                                                                                                        request</a></span></span></span></span><span
                                                                                                                                                        data-custom-class="body_text">.</span></span>
                                                                                                                                            </div>
                                                                                                                                            <style>
                                                                                                                                                ul {
                                                                                                                                                    list-style-type: square;
                                                                                                                                                }

                                                                                                                                                ul>li>ul {
                                                                                                                                                    list-style-type: circle;
                                                                                                                                                }

                                                                                                                                                ul>li>ul>li>ul {
                                                                                                                                                    list-style-type: square;
                                                                                                                                                }

                                                                                                                                                ol li {
                                                                                                                                                    font-family: Arial;
                                                                                                                                                }
                                                                                                                                            </style>
                                                                                                                                        </div>
                                                                                                                                        <div
                                                                                                                                            style="color: #595959;font-size: 14px;font-family: Arial;padding-top:16px;">
                                                                                                                                            This
                                                                                                                                            privacy
                                                                                                                                            policy
                                                                                                                                            was
                                                                                                                                            created
                                                                                                                                            using
                                                                                                                                            Termly's
                                                                                                                                            <a style="color: rgb(48, 48, 241) !important;"
                                                                                                                                                href="https://termly.io/products/privacy-policy-generator/">Privacy
                                                                                                                                                Policy
                                                                                                                                                Generator</a>.
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="p-5">