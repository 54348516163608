<div class="pe-0 pe-lg-5 pt-4">


    <h2>Contact Us</h2>

    <form [formGroup]="contactForm" (ngSubmit)="submitForm()" class="needs-validation" novalidate>

        <!-- Validation Error Summary -->
        <div *ngIf="contactForm.invalid && (contactForm.dirty || contactForm.touched)" class="alert alert-danger">
            <div *ngIf="contactForm.controls.name.errors?.['required']">Name is required.</div>
            <div *ngIf="contactForm.controls.emailAddress.errors?.['required']">Email is required.</div>
            <div *ngIf="contactForm.controls.emailAddress.errors?.['emailAddress']">Email must be a valid email address.
            </div>
            <div *ngIf="contactForm.controls.message.errors?.['required']">Message is required.</div>
            <div *ngIf="contactForm.controls.message.errors?.['maxLength']">Message cannot exceed 4000 characters.</div>
        </div>

        <!-- Name -->
        <div class="mb-3">
            <label for="name" class="form-label">Name</label>
            <input type="text" formControlName="name" class="form-control"
                [class.is-invalid]="contactForm.controls.name.invalid && contactForm.controls.name.touched" id="name"
                placeholder="Enter your name">
            <div *ngIf="contactForm.controls.name.invalid && contactForm.controls.name.touched"
                class="invalid-feedback">
                Please provide a name.
            </div>
        </div>

        <!-- Email -->
        <div class="mb-3">
            <label for="emailAddress" class="form-label">Email Address</label>
            <input type="emailAddress" formControlName="emailAddress" class="form-control"
                [class.is-invalid]="contactForm.controls.emailAddress.invalid && contactForm.controls.emailAddress.touched"
                id="email" placeholder="Enter your email">
            <div *ngIf="contactForm.controls.emailAddress.invalid && contactForm.controls.emailAddress.touched"
                class="invalid-feedback">
                Please provide a valid email.
            </div>
        </div>

        <!-- Message -->
        <div class="mb-3">
            <label for="message" class="form-label">Message</label>
            <textarea formControlName="message" rows="4" class="form-control"
                [class.is-invalid]="contactForm.controls.message.invalid && contactForm.controls.message.touched"
                id="message" placeholder="Enter your message"></textarea>
            <div *ngIf="contactForm.controls.message.invalid && contactForm.controls.message.touched"
                class="invalid-feedback">
                Please provide a message. Maximum length is 4000 characters.
            </div>
            <!-- Character Counter -->
            <small class="form-text text-muted">
                {{ contactForm.controls.message.value?.length || 0 }}/4000 characters
            </small>
        </div>




        <!-- Success Message -->
        <div *ngIf="successMessage" class="alert alert-success">
            {{ successMessage }}
        </div>

        <!-- Error Message -->
        <div *ngIf="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
        </div>
        <!-- Submit Button -->
        <button type="submit" class="btn btn-primary" [disabled]="loading || contactForm.invalid">
            {{ loading ? 'Submitting...' : 'Submit' }}
        </button>
    </form>
</div>