import { Component } from '@angular/core';

@Component({
  selector: 'app-quote-hero',
  templateUrl: './quote-hero.component.html',
  styleUrls: ['./quote-hero.component.scss']
})
export class QuoteHeroComponent {

}
