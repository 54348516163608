import { Component } from '@angular/core';

@Component({
  selector: 'app-commercialproperty',
  templateUrl: './commercialproperty.component.html',
  styleUrls: ['./commercialproperty.component.scss']
})
export class CommercialpropertyComponent {

}
