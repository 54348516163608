<!-- Your component template -->
<div class="container-fluid px-0 hero-image">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <app-service-back-button link="/services"></app-service-back-button>
          </div>
      </div>

      <!-- Add a div for white space -->
      <div class="row">
          <div class="col-12" style="height: 50px;"> <!-- Adjust the height as needed -->
              <!-- Empty div for white space -->
          </div>
      </div>

      <div class="row row-cols-1 row-cols-md-1 row-cols-xl-2 g-5">
          <app-services-card linkText="Get a FREE Quote" header="General Liability" link="/quote" header_min="25px" description="General Liability insurance protects businesses from claims that it has caused harm to others such as: Bodily Injury, Property Damage, Reputational Harm, and Advertising Injury"></app-services-card> 
          <app-services-card linkText="Get a FREE Quote" header="Professional Liability" link="/quote" header_min="25px" description="Professional Liability insurance protects from negligence and other claims for roles such as: Doctors, Lawyers, Accountants, and IT Specialists"></app-services-card>
      </div>
  </div>
</div>
