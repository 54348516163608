<div class="hero-image">

</div>
<style>
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');
</style>
<div class="container-fluid p-0">
  <div class="row g-0">
      <div class="col col-12 col-lg-7">
          <div class="thumbs-up-div">
              <div class="about-summary text-light">
                  <h1>
                      <strong>Why Perlas?</strong>
                  </h1>
                  <div>
                    <p class="pt-3 fs-5">
                      Founded in 1990, Perlas Insurance Services is a family owned and operated insurance brokerage, specializing in commercial insurance for the healthcare industry and small businesses. 
                    </p>
                    <p class="fs-5">
                      With over three decades of experience, the company is proud to service over 3000+ healthcare facilities throughout the West Coast region, maintaining a policy retention rate of over 90%. Headquartered in Las Vegas, Perlas Insurance Services aims to deliver the proper coverage with quality products at the lowest rate to meet your insurance needs.​
                    </p>
                  </div>
                  
                  <div class="learn-more-container text-center" [style.opacity]="learnMoreOpacity">
                    <div class="learn-more yellow-text" id="learnMore" (click)="scrollToContent()">Why Do Business With Us<br><i class="fas fa-chevron-down"></i></div>
                  </div>
              </div>
              
          </div>
      </div>
      <div class="col col-12 col-lg-5">
          <div class="col col-12 col-lg-12">
              <div class="patrick-computer-div d-flex text-center align-items-center justify-content-center">
                <a class="btn btn-custom fs-4" routerLink="/about/meet-the-ceo">Meet The CEO</a>
              </div>
          </div>
          <div class="col col-12 col-lg-12">
              <div class="perlas-building-div">
                  
              </div>
          </div>
      </div>
  </div>
</div>
